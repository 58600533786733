import { Navigate, Route, Routes } from "react-router-dom";
import { connect } from "react-redux";

import * as actions from "../../store/actions/index";

import SideNavigationBar from "../../components/sideNavigationBar/SideNavigationBar";
import AddProduct from "../../views/setup/products/product/AddProduct";
import AssemblyItems from "../../views/setup/assemblyItems/AssemblyItems";
import Brands from "../../views/setup/brands/Brands";
import CertifiedByOptions from "../../views/setup/certifiedByOptions/CertifiedByOptions";
import Customers from "../../views/setup/customers/Customers";
import Dashboard from "../../views/dashboard/Dashboard";
import Manufacturers from "../../views/setup/manufacturers/Manufacturers";
import Preservatives from "../../views/setup/preservatives/Preservatives";
import ProductScreen from "../../views/setup/products/product/ProductScreen";
import Products from "../../views/setup/products/Products";
import MyIngredients from "../../views/setup/myIngredients/MyIngredients";
import Allergens from "../../views/setup/allergens/Allergens";
import Claims from "../../views/setup/claims/Claims";
import Employees from "../../views/setup/employees/Employees";
import ProductionCosts from "../../views/setup/productionCosts/ProductionCosts";
import ProductTypes from "../../views/setup/productTypes/ProductTypes";
import UnitOfMeasures from "../../views/setup/unitOfMeasures/UnitOfMeasures";
import Recipes from "../../views/recipes/Recipes";
import CreateRecipe from "../../views/recipes/CreateRecipe";

import Users from "../../views/users/Users";

import ReceivingInvoiceIndex from "../../views/receiving/invoices/ReceivingInvoiceIndex";
import AllReceivingInvoices from "../../views/receiving/invoices/AllReceivingInvoices";
import AddReceivingInvoice from "../../views/receiving/invoices/AddReceivingInvoice";

import NewPackageScreen from "../../views/packaging/NewPackageScreen";
import NewPackageScreenV2 from "../../views/packaging/NewPackageScreenV2";
import PackagingRecords from "../../views/packaging/PackagingRecords";

import { useEffect, useRef, useState } from "react";

import { classNames, withRouter } from "../../shared/utility";
import MyAccount from "../../views/myAccount/MyAccount";

import ManufactureNewProduct from "../../views/production/ManufactureNewProduct";
import ManufacturedProducts from "../../views/production/ManufacturedProducts";
import ReceivingInvoiceDetails from "../../views/receiving/invoices/details/ReceivingInvoiceDetails";
import CostCalcution from "../../views/costCalculation/CostCalculation";
import ManufacturedProductDetails from "../../views/production/ManufacturedProductDetails";
import QaReportsScreen from "../../views/qaReports/QaReportsScreen";
import RecipeDetails from "../../views/recipes/RecipeDetails";
import InventoryRawMaterialScreen from "../../views/inventory/rawMaterial/InventoryRawMaterialScreen";
import InventoryManufacturedMaterialScreen from "../../views/inventory/manufacturedMaterial/InventoryManufacturedMaterialScreen";
import ManageOrders from "../../views/orders/ManageOrders";
import CreateOrder from "../../views/orders/CreateOrder";
import OrderDetails from "../../views/orders/OrderDetails";
import ManageShippings from "../../views/shipping/ManageShippings";
import CreateShipping from "../../views/shipping/CreateShipping";
import ShippingDetails from "../../views/shipping/ShippingDetails";
import HeaderLayout from "./HeaderLayout";
import Traceability from "../../views/traceability/Traceability";
import CostcoSkus from "../../views/orders/CostcoSkus";
import TemperatureSensors from "../../views/qaReports/TemperatureSensors";
import TemperatureSensorDetails from "../../views/qaReports/TemperatureSensorDetails";
import ExportLabel from "../../views/qaReports/exportLabel/ExportLabel";
import MediaManager from "../../views/mediaManager/MediaManager";
import { Toast } from "primereact/toast";

function DefaultLayout(props) {
  const { logout, user_type, snackbarQueue, removeSnackbar } = props;
  const [sidebarOpen, setSidebarOpen] = useState(true);

  const size = useWindowSize();
  const toast = useRef(null);

  useEffect(() => {
    snackbarQueue.forEach((snackbar) => {
      console.log("");
      toast.current.show({
        severity: "success",
        summary: snackbar.message,
        content: null,
        life: 3000,
      });
      removeSnackbar(snackbar.id);
    });
  }, [snackbarQueue]);

  useEffect(() => {
    if (size.width < 991) {
      setSidebarOpen(false);
    } else {
      setSidebarOpen(true);
    }
  }, [size]);

  const outsideClickRef = useRef(null);

  useEffect(() => {
    /**
     * Alert if clicked on outside of element
     */
    function handleClickOutside(event) {
      if (
        outsideClickRef.current &&
        !outsideClickRef.current.contains(event.target) &&
        size.width < 991
      ) {
        setSidebarOpen(false);
      }
    }
    // Bind the event listener
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [outsideClickRef, size]);

  return (
    <div
      className={classNames(
        sidebarOpen
          ? size.width < 991
            ? "scroll-blocked layout-mobile-active"
            : ""
          : "layout-static-inactive",
        "layout-container layout-static layout-light"
      )}
    >
      <SideNavigationBar
        user_type={user_type}
        outsideClickRef={outsideClickRef}
      />
      <Toast ref={toast} />
      <div className="layout-content-wrapper">
        <div className="layout-mask"></div>
        <HeaderLayout
          sidebarOpen={sidebarOpen}
          logout={logout}
          setSidebarOpen={setSidebarOpen}
        />
        {parseInt(user_type) === 2 ? (
          <Routes>
            <Route path="/export-label" element={<ExportLabel />} />
            <Route path="/my-account" element={<MyAccount />} />
            <Route path="*" element={<Navigate to="/export-label" replace />} />
          </Routes>
        ) : (
          <Routes>
            <Route path="/dashboard" element={<Dashboard />} />

            <Route path="/my-account" element={<MyAccount />} />

            <Route path="/supplier-items" element={<AssemblyItems />} />

            <Route path="/brands" element={<Brands />} />

            <Route path="/customers" element={<Customers />} />

            <Route
              path="/certification-types"
              element={<CertifiedByOptions />}
            />

            <Route path="/employees" element={<Employees />} />

            <Route path="/cost-calculation" element={<CostCalcution />} />

            <Route path="/manufacturers" element={<Manufacturers />} />

            <Route path="/product-allergens" element={<Allergens />} />
            <Route path="/product-claims" element={<Claims />} />
            <Route path="/product-types" element={<ProductTypes />} />
            <Route path="/production-costs" element={<ProductionCosts />} />
            <Route path="/bom-items" element={<MyIngredients />} />
            <Route path="/my-products" element={<Products />} />
            <Route path="/my-products/new" element={<AddProduct />} />
            <Route path="/my-products/:productId" element={<ProductScreen />} />
            <Route path="/preservatives" element={<Preservatives />} />

            <Route
              path="/receiving-index"
              element={<ReceivingInvoiceIndex />}
            />
            <Route
              path="/receiving-invoices"
              element={<AllReceivingInvoices />}
            />
            <Route
              path="/receiving-invoices/new"
              element={<AddReceivingInvoice />}
            />
            <Route
              path="/receiving-invoices/:invoiceId"
              element={<ReceivingInvoiceDetails />}
            />

            <Route
              path="/manufacture-product"
              element={<ManufactureNewProduct />}
            />
            <Route
              path="/manufactured-products"
              element={<ManufacturedProducts />}
            />
            <Route
              path="/manufactured-products/:productId"
              element={<ManufacturedProductDetails />}
            />

            <Route path="/packaging-reports" element={<PackagingRecords />} />
            <Route path="/packaging-new" element={<NewPackageScreen />} />
            <Route path="/packaging-new2" element={<NewPackageScreenV2 />} />

            <Route path="/unit-of-measures" element={<UnitOfMeasures />} />

            <Route path="/qa-reports" element={<QaReportsScreen />} />
            <Route path="/media-manager" element={<MediaManager />} />

            <Route path="/recipes" element={<Recipes />} />
            <Route path="/recipes/:recipeId" element={<RecipeDetails />} />
            <Route path="/create-recipe" element={<CreateRecipe />} />

            <Route
              path="/inventory-raw-material"
              element={<InventoryRawMaterialScreen />}
            />
            <Route
              path="/inventory-manufactured-material"
              element={<InventoryManufacturedMaterialScreen />}
            />

            <Route path="/orders" element={<ManageOrders />} />
            <Route path="/orders/:orderId" element={<OrderDetails />} />
            <Route path="/create-order" element={<CreateOrder />} />
            <Route path="/import-skus" element={<CostcoSkus />} />

            <Route path="/shippings" element={<ManageShippings />} />
            <Route path="/create-shipping" element={<CreateShipping />} />
            <Route
              path="/shippings/:shippingId"
              element={<ShippingDetails />}
            />

            <Route
              path="/temperature-sensors"
              element={<TemperatureSensors />}
            />
            <Route
              path="/temperature-sensors/:sensorId"
              element={<TemperatureSensorDetails />}
            />

            <Route path="/traceability" element={<Traceability />} />

            <Route path="/users" element={<Users />} />

            <Route path="*" element={<Navigate to="/dashboard" replace />} />
          </Routes>
        )}
      </div>
    </div>
  );
}

const mapStateToProps = (state) => {
  return {
    user_type: state.auth.user_type,
    snackbarQueue: state.utilities.snackbarQueue,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    logout: () => dispatch(actions.logout()),
    removeSnackbar: (snackbarId) =>
      dispatch(actions.removeSnackbar(snackbarId)),
  };
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(DefaultLayout)
);

function useWindowSize() {
  // Initialize state with undefined width/height so server and client renders match
  // Learn more here: https://joshwcomeau.com/react/the-perils-of-rehydration/
  const [windowSize, setWindowSize] = useState({
    width: undefined,
    height: undefined,
  });
  useEffect(() => {
    // Handler to call on window resize
    function handleResize() {
      // Set window width/height to state
      setWindowSize({
        width: window.innerWidth,
        height: window.innerHeight,
      });
    }
    // Add event listener
    window.addEventListener("resize", handleResize);
    // Call handler right away so state gets updated with initial window size
    handleResize();
    // Remove event listener on cleanup
    return () => window.removeEventListener("resize", handleResize);
  }, []); // Empty array ensures that effect is only run on mount
  return windowSize;
}
