import React from "react";
import SelectMenu from "../../../controls/SelectMenu";
import {
  convertToDefEventPara,
  productionStatusOptions,
} from "../../../../shared/utility";

export default function ManufacturingRecipeSelect(props) {
  const {
    isEdit,
    manufacturedProduct,
    onFetchRecipeItems,
    recipes,
    handleInputChange,
  } = props;

  var chosenRecipe = [];
  if (manufacturedProduct) {
    if (manufacturedProduct.selectedRecipe) {
      if (manufacturedProduct.selectedRecipe.recipe) {
        chosenRecipe = recipes.find(
          (el) => el.value === manufacturedProduct.selectedRecipe.recipe.id
        );
      }
    }
  }

  var currentStatus = [];
  if (manufacturedProduct) {
    if (manufacturedProduct.status) {
      currentStatus = productionStatusOptions.find(
        (el) => el.value === manufacturedProduct.status
      );
    }
  }

  return (
    <div className="p-4 card-custom">
      <div className="grid grid-cols-1 gap-2">
        <div className="col-span-1">
          <SelectMenu
            handleInputChange={(e) => onFetchRecipeItems(e.target.value.value)}
            name="selected_recipe"
            options={recipes}
            defaultValue={isEdit ? chosenRecipe : null}
            title={"Recipe"}
          />
        </div>
        <div className="col-span-1">
          <SelectMenu
            name="status"
            handleInputChange={(e) => {
              handleInputChange(
                convertToDefEventPara("status", e.target.value.value)
              );
            }}
            options={productionStatusOptions}
            defaultValue={isEdit ? currentStatus : null}
            title={"Status"}
          />
        </div>
      </div>
    </div>
  );
}
