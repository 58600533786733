import { useEffect, useRef, useState } from "react";
import { ManageShippingsViewModel } from "../../viewmodels/shipping/ManageShippingsViewModel";
import { MagnifyingGlassIcon } from "@heroicons/react/24/outline";

import { connect } from "react-redux";

import * as actions from "../../store/actions/index";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { useNavigate } from "react-router-dom";

import {
  formatDateWithTime,
  orderStatusOptions,
  withRouter,
} from "../../shared/utility";

import EmptyModal from "../../components/modals/EmptyModal";
import SlideOver from "../../components/slideOver/SlideOver";
import SimpleBanner from "../../components/SimpleBanner";
import { Link } from "react-router-dom";

import { Paginator } from "primereact/paginator";
import FilterDropdown from "../../components/filterDropdown/FilterDropdown";
import Pills from "../../components/controls/Pills";

function ManageShippings(props) {
  const { logout } = props;
  const {
    getShippings,
    loading,
    shippings,
    showSimpleNotification,
    setEmptyModelOpen,
    setShowSimpleNotification,
    setSlideOver,
    slideOver,
    emptyModal,
    setShowFilters,
    showFilters,
    resetFilters,
    filterComponents,
    getCustomersForDropdown,
    filters,
    askDeleteShipping,
    exportOrdersForNetSuite,
    selectedProducts,
    setSelectedProducts,
  } = ManageShippingsViewModel(logout);

  const navigate = useNavigate();

  var currentPage = 1;
  useEffect(() => {
    getCustomersForDropdown();
    getShippings(null, currentPage, filters);
  }, []);

  const [first, setFirst] = useState(1);
  const [rows, setRows] = useState(20);

  const searchRef = useRef();

  const handleSearch = () => {
    getShippings(searchRef.current.value, currentPage, filters);
  };

  const handlePagination = (event) => {
    currentPage = event.page + 1;
    setFirst(event.first);
    setRows(event.rows);
    getShippings(searchRef.current.value, currentPage, filters);
  };

  const convertStatus = (status) => {
    const statusOption = orderStatusOptions.filter((el) => el.value === status);

    return statusOption.length === 1
      ? { text: statusOption[0].label, type: statusOption[0].type }
      : { text: orderStatusOptions[0].label, type: orderStatusOptions[0].type };
  };

  const dateCreatedBody = (rowData) => {
    return formatDateWithTime(rowData.created_at);
  };

  const statusBody = (rowData) => {
    return <Pills content={convertStatus(rowData.status)} />;
  };

  const massItemActionTemplate = (rowData) => {
    return (
      <div className="flex">
        <Link
          className="rounded-md bg-main-purple px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-600 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
          to={`/shippings/${rowData.id}`}
        >
          Edit
        </Link>
        <button
          onClick={() => {
            askDeleteShipping(rowData);
          }}
          className="ml-2 rounded-md bg-red-600 px-3 py-1.5 text-sm font-semibold text-white shadow-sm hover:bg-red-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-red-600"
        >
          Delete
        </button>
      </div>
    );
  };

  return (
    <div>
      <SimpleBanner
        setShow={setShowSimpleNotification}
        showSimpleNotification={showSimpleNotification}
      />
      <div className="mt-2 lg:px-8">
        <div className="sm:flex sm:items-center px-4 py-5 card-custom">
          <div className="sm:flex-auto">
            <h1 className="text-base font-semibold leading-6 text-gray-900">
              Shippings
            </h1>
            <div className="mt-5 block sm:flex rounded-md space-y-4 sm:space-y-0">
              <div className="relative flex items-stretch flex-grow focus-within:z-10">
                <input
                  type="search"
                  name="searchProduct"
                  id="searchProduct"
                  className="block w-full rounded-lg rounded-l-md pl-2 sm:text-sm border border-gray-300 rounded-tr-none rounded-br-none"
                  placeholder="Search by name"
                  ref={searchRef}
                  onKeyDown={(e) => {
                    if (e.key === "Enter") handleSearch();
                  }}
                />

                <button
                  type="button"
                  className="-ml-px relative inline-flex items-center space-x-2 px-4 py-2 border border-gray-300 text-sm font-medium rounded-r-md text-gray-700 bg-gray-50 hover:bg-gray-100 focus:outline-none focus:ring-1"
                  onClick={() => handleSearch()}
                >
                  <MagnifyingGlassIcon
                    className="h-5 w-5 text-gray-400"
                    aria-hidden="true"
                  />
                </button>
              </div>

              <div className="flex">
                <FilterDropdown
                  actionCall={() => handleSearch()}
                  filterComponents={filterComponents}
                  resetFilters={() => {
                    resetFilters();
                  }}
                  showFilters={showFilters}
                  setShowFilters={setShowFilters}
                />

                <div>
                  <button
                    className="ml-2 rounded-md bg-green-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-green-600 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-green-600"
                    onClick={exportOrdersForNetSuite}
                  >
                    Export
                  </button>
                </div>
                <div>
                  <button
                    className="ml-2 rounded-md bg-main-purple px-2 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-600 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                    onClick={() => navigate("/create-shipping")}
                  >
                    Create Shipping
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="mt-8 flow-root overflow-x-auto">
          <div className="inline-block min-w-full py-2 align-middle">
            {loading ? (
              <div>
                <div className="border h-10 shadow rounded-md p-4 w-full mx-auto">
                  <div className="animate-pulse flex space-x-4">Loading</div>
                </div>
              </div>
            ) : (
              <div className="overflow-hidden card-custom">
                <DataTable
                  value={shippings.data}
                  selectionMode={"checkbox"}
                  selection={selectedProducts}
                  onSelectionChange={(e) => setSelectedProducts(e.value)}
                  dataKey="id"
                  tableClassName="min-w-full bg-white divide-y divide-gray-300 text-sm"
                  cellClassName="p-2 bg-white border-1-bottom-gray text-sm"
                >
                  <Column
                    selectionMode="multiple"
                    headerStyle={{ width: "5%" }}
                  ></Column>
                  <Column
                    field="po_number"
                    header="PO #"
                    headerStyle={{ width: "15%" }}
                  />
                  <Column
                    field="customer"
                    header="Customer"
                    headerStyle={{ width: "30%" }}
                  />
                  <Column
                    header="Date Created"
                    body={dateCreatedBody}
                    headerStyle={{ width: "20%" }}
                  />
                  <Column
                    header="Status"
                    body={statusBody}
                    headerStyle={{ width: "20%" }}
                  />
                  <Column
                    header=""
                    body={massItemActionTemplate}
                    headerStyle={{ width: "20%" }}
                  />
                </DataTable>
              </div>
            )}
          </div>
          <div className="w-full">
            <Paginator
              first={first}
              rows={rows}
              totalRecords={shippings.total}
              onPageChange={handlePagination}
            />
          </div>
        </div>
      </div>
      <EmptyModal
        childComponent={emptyModal.childComponent}
        open={emptyModal.open}
        setOpen={setEmptyModelOpen}
      />
      <SlideOver
        childComponent={slideOver.childComponent}
        slideOver={slideOver}
        customClass="max-w-3xl"
        setSlideOver={setSlideOver}
      />
    </div>
  );
}

const mapStateToProps = (state) => {
  return {};
};

const mapDispatchToProps = (dispatch) => {
  return {
    logout: () => dispatch(actions.logout()),
  };
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(ManageShippings)
);
