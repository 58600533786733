import { useState } from "react";

import { makePostApiCall } from "../../shared/makeApiCall";
import DateRangeFilter from "../../components/filterDropdown/DateRangeFilter";
import { BannerNotificationType } from "../../components/SimpleBanner";
import TemperatureSensorForm from "../../components/forms/qaReports/TemperatureSensorForm";
import TemperatureSensorsReportModal from "../../components/forms/qaReports/temperatureSensors/TemperatureSensorsReportModal";

export function TemperatureSensorDetailsViewModel(logout, sensorId) {
  const [filters, setFilters] = useState({
    startDate: null,
    endDate: null,
  });
  const [loading, setLoading] = useState(true);
  const [sensor, setSensor] = useState({ data: [] });
  const [emptyModal, setEmptyModelOpen] = useState({
    open: false,
    childComponent: null,
  });
  const [showFilters, setShowFilters] = useState(false);
  const [showSimpleNotification, setShowSimpleNotification] = useState({
    show: false,
    message: null,
    title: null,
    type: BannerNotificationType.Success,
  });
  const [slideOver, setSlideOver] = useState({
    childComponent: null,
    open: false,
  });

  const getSensorData = async function (page) {
    setLoading(true);
    let callResult = await makePostApiCall(`temperature-sensors/${sensorId}`, {
      page: page,
      filters: filters,
    });

    if (callResult.success) {
      setSensor(callResult.data.data);
    } else {
      if (callResult.errorStatus === 401) {
        logout();
      }
    }
    setLoading(false);
  };

  const resetFilters = () => {
    const filterReset = {
      supervisor: { value: -1, label: "All Employees" },
      employee1: { value: -1, label: "All Employees" },
      employee2: { value: -1, label: "All Employees" },
      certification: { value: -1, label: "All Certifications" },
      recipe: { value: -1, label: "All Recipes" },
      grandTotal: null,
      startDate: null,
      endDate: null,
    };
    setFilters(filterReset);

    setShowFilters(false);
  };

  const filterComponents = [
    <DateRangeFilter
      endDate={filters.endDate}
      onChange={(name, value) =>
        setFilters({
          ...filters,
          [name]: value,
        })
      }
      resetFilter={() =>
        setFilters({
          ...filters,
          startDate: null,
          endDate: null,
        })
      }
      startDate={filters.startDate}
    />,
  ];

  const updateSensor = async function (values) {
    let callResult = await makePostApiCall(
      `updateSensor/${sensor.sensor_id}`,
      values
    );

    if (callResult.success) {
      setShowSimpleNotification({
        show: true,
        title: "Sensor updated",
        type: BannerNotificationType.Success,
      });
      setSlideOver({
        childComponent: null,
        open: false,
      });
      setTimeout(function () {
        getSensorData();
      }, 1000);
    } else {
      if (callResult.errorStatus === 401) {
        logout();
      }
    }
  };

  const openEditProductSidePanel = () => {
    setSlideOver({
      childComponent: (
        <TemperatureSensorForm
          sensor={sensor}
          isEdit={false}
          setOpen={setSlideOver}
          actionCall={updateSensor}
        />
      ),
      open: true,
    });
  };

  const onGenerateSensorTemperatureReport = async function (values) {
    let callResult = await makePostApiCall(
      `generateSensorTemperatureReport/${sensorId}`,
      values
    );

    if (callResult.success) {
      setShowSimpleNotification({
        show: true,
        title: "Report generated",
        type: BannerNotificationType.Success,
      });
      setEmptyModelOpen({
        childComponent: null,
        open: false,
      });
      window.open(callResult.data.location, "_blank");
    } else {
      if (callResult.errorStatus === 401) {
        logout();
      }
    }
  };

  const openTemperatureSensorReportsModal = () => {
    setEmptyModelOpen({
      childComponent: (
        <TemperatureSensorsReportModal
          setOpen={setEmptyModelOpen}
          confirmAction={onGenerateSensorTemperatureReport}
        />
      ),
      open: true,
    });
  };

  return {
    loading,
    getSensorData,
    sensor,
    filterComponents,
    resetFilters,
    showFilters,
    setShowFilters,
    openEditProductSidePanel,
    slideOver,
    setSlideOver,
    showSimpleNotification,
    setShowSimpleNotification,
    emptyModal,
    setEmptyModelOpen,
    openTemperatureSensorReportsModal,
    filters,
  };
}
