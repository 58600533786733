import React from "react";
import { BrowserRouter } from "react-router-dom";

import { Provider } from "react-redux";
// todo oggy replace createStore
import { createStore, applyMiddleware, compose, combineReducers } from "redux";

import authReducer from "./store/reducers/auth";
import utilitiesReducer from "./store/reducers/utilities";

import thunk from "redux-thunk";
import ReactDOM from "react-dom/client";
import "./index.css";

import App from "./App/App";

import "primereact/resources/primereact.css";
import "primereact/resources/themes/lara-light-indigo/theme.css";

let composeEnhancers = null;
if (process.env.NODE_ENV === "development") {
  composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
} else {
  composeEnhancers = compose;
}

const rootReducer = combineReducers({
  auth: authReducer,
  utilities: utilitiesReducer,
});

const store = createStore(
  rootReducer,
  composeEnhancers(applyMiddleware(thunk))
);

const app = (
  <Provider store={store}>
    <BrowserRouter>
      <App />
    </BrowserRouter>
  </Provider>
);

const root = ReactDOM.createRoot(document.getElementById("root"));

root.render(
  <Provider store={store}>
    <BrowserRouter>
      <App />
    </BrowserRouter>
  </Provider>
);
