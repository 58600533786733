import { classNames } from "../../../../shared/utility";

export default function ExportLabelToPrint(props) {
  const { certImage, label, showOnScreen } = props;

  return (
    <div
      className={classNames(
        showOnScreen ? null : "hidden",
        "export-label-section-to-print"
      )}
    >
      <img
        src={certImage}
        alt="Certification image"
        width="100px"
        height="100px"
        className="ml-auto mr-auto"
      />

      <div className="align-bottom">
        <p className="mt-2 font-bold text-2xl">Cert. No. Cert</p>

        <p className="mt-2 text-3xl">{label}</p>
      </div>
    </div>
  );
}
