import React, { useEffect, useRef, useState } from "react";

import { useForm } from "../useForm";

import SelectMenu from "../../controls/SelectMenu";
import {
  convertToDefEventPara,
  makeBeepNoise,
  orderStatusOptions,
  updateObjectInArray,
} from "../../../shared/utility";
import OrderItemsForShipping from "./OrderItemsForShipping";
import DeleteConfirmationForm from "../DeleteConfirmationForm";
import { classNames } from "primereact/utils";
import Input from "../../controls/Input";
import QuickScanAddToShippingForm from "./QuickScanAddToShippingForm";
import { Accordion, AccordionTab } from "primereact/accordion";

const initialFValues = {
  id: 0,
  customer_id: 0,
  subtotal_dollar_amount: 0,
  total_dollar_amount: 0,
  order_id: 0,
  orderItems: [],
};

export default function CreateNewShippingForm(props) {
  const {
    orders,
    onFetchOrderItems,
    isEdit,
    loading,
    shipping,
    setEmptyModelOpen,
    showErrorModal,
    onFetchPackagedItemByBarcode,
    updateShippingApiCall,
    createShippingApiCall,
    addPackagedItemToOrderItem,
    deleteShippingItemApiCall,
    addSkidLabelToOrderItem,
    employeeOptions,
    deleteShipping,
    printShipping,
    quickAddPackagedItemToOrderItem,
    fetchPackagedItemsForQuickScan,
    deleteQuickScanShippingItemByLot,
    exportOrderForNetSuite,
    getOrderItemsOnly,
    subItemsLoading,
    orderItemShipments,
    getOrderItemShipments,
  } = props;

  const [barcodeScanInProgress, setBarcodeScanInProgress] = useState(false);
  const [quickScan, setQuickScan] = useState(false);

  const barcodeInputRef = useRef(null);

  const validate = (fieldValues = values) => {
    let temp = { ...errors };

    temp.order_id = null;
    if (values.order_id === 0) {
      temp.order_id = "Please fill out field.";
    }

    setErrors({
      ...temp,
    });

    if (fieldValues === values) {
      return Object.values(temp).every((x) => x === null);
    }
  };

  const { values, setValues, errors, setErrors, handleInputChange } = useForm(
    initialFValues,
    false,
    validate
  );

  useEffect(() => {
    if (shipping) {
      const shippingCopy = {
        ...shipping,
        status: orderStatusOptions.filter(
          (option) => option.value == shipping.status
        )[0],
      };
      setValues(shippingCopy);
    }
  }, [shipping]);

  function findIndexForOrderItem(product_id) {
    return values.orderItems.findIndex(
      (element) => element.product_id === product_id
    );
  }

  const updateShipping = () => {
    if (validate()) {
      updateShippingApiCall(values);
    }
  };

  const createShipping = () => {
    if (isEdit) {
      updateShipping();
    } else {
      if (validate()) {
        createShippingApiCall(values);
      }
    }
  };

  const normalItemScanned = async function (packagedItem, itemIndex) {
    const orderItems = [...values.orderItems];
    const orderItem = orderItems[itemIndex];

    // const packageAlreadyScanned = orderItem.shippingItems.findIndex(
    //   (element) => {
    //     return element.id === packagedItem.id;
    //   }
    // );
    // if (packageAlreadyScanned >= 0) {
    //   showErrorModal("Item already scanned");
    //   return;
    // }

    let callResult = { success: false };
    if (isEdit) {
      callResult = await addPackagedItemToOrderItem(
        values.id,
        orderItem,
        packagedItem
      );
    }

    if (callResult.success || !isEdit) {
      let newShippingItems = [packagedItem];
      if (orderItem.shippingItems) {
        newShippingItems = [...orderItem.shippingItems, packagedItem];
      }
      const shipped_amount = orderItem.shipped_amount
        ? parseFloat(orderItem.shipped_amount)
        : 0;

      const newAmountBeingShipped =
        orderItem.selling_unit_of_measure === "Kg"
          ? parseFloat(packagedItem.packing_size)
          : 1;

      const orderItemBeingEdited = {
        ...orderItem,
        shipped_amount: shipped_amount + newAmountBeingShipped,
        shippingItems: newShippingItems,
      };
      var payload = {
        index: itemIndex,
        item: orderItemBeingEdited,
      };
      const newOrderItems = updateObjectInArray(orderItems, payload);
      setValues({
        ...values,
        orderItems: newOrderItems,
        scanned_box_label: "",
      });
    }
  };

  const skidLabelScanned = async function (packagedItems, itemIndex) {
    const orderItems = [...values.orderItems];
    const orderItem = orderItems[itemIndex];

    var newItems = packagedItems.packaged_item.filter(function (obj) {
      return (
        orderItem.shippingItems.findIndex((item) => item.id === obj.id) === -1
      );
    });

    let callResult = { success: false };
    if (isEdit) {
      callResult = await addSkidLabelToOrderItem(
        values.id, // this is the shipping id
        orderItem.id,
        newItems.map((item) => item.id)
      );
    }

    if (callResult.success || !isEdit) {
      let newShippingItems = [...newItems];
      if (orderItem.shippingItems) {
        newShippingItems = [...orderItem.shippingItems, ...newItems];
      }
      const shipped_amount = orderItem.shipped_amount
        ? parseInt(orderItem.shipped_amount)
        : 0;

      const orderItemBeingEdited = {
        ...orderItem,
        shipped_amount: shipped_amount + packagedItems.count,
        shippingItems: newShippingItems,
      };
      var payload = {
        index: itemIndex,
        item: orderItemBeingEdited,
      };
      const newOrderItems = updateObjectInArray(orderItems, payload);
      setValues({
        ...values,
        orderItems: newOrderItems,
        scanned_box_label: "",
      });
    }
  };

  const quickAddScannedItemAmount = async function (
    packagedItem,
    orderItemIndex,
    newValues,
    original_scanned_amount
  ) {
    const orderItems = [...values.orderItems];
    const orderItem = orderItems[orderItemIndex];
    console.log("orderItem.shippingItems", orderItem.shippingItems);
    console.log("newValues", newValues);
    console.log("packagedItem", packagedItem);

    /*
     * If the user decreases the amount of quantity, then undo the quick add ones only
     */
    let callResult = { success: false };
    let newLotItem = {
      product_lot: packagedItem.product_lot,
      is_lot: true,
      description_en: packagedItem.description_en,
      product_name: packagedItem.product_name,
      quantity: parseInt(newValues.quantity),
      inventory_on_hand: newValues.inventory_on_hand,
      packaging_date: packagedItem.packaging_date,
      packing_size: packagedItem.packing_size,
      product_id: packagedItem.product_id,
      product_code: packagedItem.product_code,
      best_before_date: packagedItem.best_before_date,
      order_item_id: orderItem.id,
      shippingItems: [],
    };
    var payload = {
      product_lot: newLotItem.product_lot,
      product_code: newLotItem.product_code,
      order_item_id: orderItem.id,
      quantity: parseFloat(newValues.quantity),
    };
    callResult = await fetchPackagedItemsForQuickScan(payload);
    if (callResult.success) {
      newLotItem = { ...newLotItem, shippingItems: [...callResult.data] };
    }

    if (isEdit) {
      callResult = await quickAddPackagedItemToOrderItem(
        values.id,
        orderItem.id,
        newLotItem,
        original_scanned_amount
      );
    }
    const resultOrderItems = await getOrderItemsOnly();
    if (resultOrderItems.success) {
      setValues({
        ...values,
        orderItems: resultOrderItems.data,
        scanned_box_label: "",
      });
    }

    /*
     * Since this is quick add and we need to display the serial numbers below, fetch the quantity amount from backend
     * then add those serial numbers below
     */
    // if (callResult.success) {
    //   console.log("orderItem.shippingItems", orderItem.shippingItems);
    //   let currShippingItems = orderItem.shippingItems.filter(
    //     (el) =>
    //       parseInt(el.product_lot) !== parseInt(packagedItem.product_lot) &&
    //       el.packaging_date !== packagedItem.packaging_date
    //   );
    //   console.log("currShippingItems", currShippingItems);

    //   let newShippingItems = [...currShippingItems, newLotItem];

    //   let newTotal = 0;
    //   newShippingItems.forEach((el) => {
    //     if (el.is_lot) {
    //       newTotal += el.shippingItems.length;
    //     } else {
    //       newTotal++;
    //     }
    //   });

    //   const orderItemBeingEdited = {
    //     ...orderItem,
    //     shipped_amount: newTotal,
    //     shippingItems: newShippingItems,
    //     is_quick_scan: true,
    //   };
    //   payload = {
    //     index: orderItemIndex,
    //     item: orderItemBeingEdited,
    //   };
    //   const newOrderItems = updateObjectInArray(orderItems, payload);
    //   setValues({
    //     ...values,
    //     orderItems: newOrderItems,
    //     scanned_box_label: "",
    //   });
    // }
    setEmptyModelOpen({
      childComponent: null,
      open: false,
    });
  };

  /*
   * The edit button will open the popup again and display the same information.
   * If its new shipping folow, on enter it will query the items again.
   * If the shipping is being edited, then it will remove all quick_scan items for that shipping and query new ones
   */
  const openQuickScanPopupWithFetchLatest = async function (item) {
    // let callResult = await onFetchPackagedItemByBarcode(item);
    const orderItemIndex = findIndexForOrderItem(item.product_id);
    console.log("orderItemIndex", orderItemIndex);
    openQuickScanPopup(item, orderItemIndex, item.shippingItems.length);
  };

  const openQuickScanPopup = (item, orderItemIndex, existingQuantity) => {
    const orderItems = [...values.orderItems];
    const orderItem = orderItems[orderItemIndex];

    var currQuantity = 0;
    var tempExistingQuantity = 0;
    if (existingQuantity < 1 || typeof existingQuantity === "undefined") {
    } else {
      currQuantity = existingQuantity;
      tempExistingQuantity = existingQuantity;
    }

    setEmptyModelOpen({
      childComponent: (
        <QuickScanAddToShippingForm
          confirmAction={(newValues) =>
            quickAddScannedItemAmount(
              item,
              orderItemIndex,
              newValues,
              tempExistingQuantity
            )
          }
          isEdit={isEdit}
          item={item}
          setOpen={setEmptyModelOpen}
          currQuantity={currQuantity}
          orderQuantity={orderItem.quantity}
          selling_unit_of_measure={orderItem.selling_unit_of_measure}
        />
      ),
      open: true,
    });
  };

  const handleBarcodeScan = async function (scannedBarcode) {
    setValues({
      ...values,
      scanned_box_label: "",
    });
    if (barcodeInputRef.current) {
      barcodeInputRef.current.focus();
    }
    let callResult = await onFetchPackagedItemByBarcode(scannedBarcode);
    if (callResult.success) {
      makeBeepNoise();
      const packagedItem = callResult.data;
      var prodIdToSearch = packagedItem.packaged_item.product_id;
      if (packagedItem.is_skid_label) {
        if (packagedItem.packaged_item.length > 0) {
          prodIdToSearch = packagedItem.packaged_item[0].product_id;
        } else {
          showErrorModal("No items for skid label");
          return;
        }
      }
      const itemIndex = findIndexForOrderItem(prodIdToSearch);

      if (itemIndex >= 0) {
        if (packagedItem.is_skid_label) {
          skidLabelScanned(packagedItem, itemIndex);
        } else if (quickScan) {
          openQuickScanPopup(packagedItem.packaged_item, itemIndex);
        } else {
          normalItemScanned(packagedItem.packaged_item, itemIndex);
        }
      } else {
        showErrorModal("Item not in order");
      }
    } else {
      showErrorModal("We could not find that item.");
    }
  };

  const onDeleteQuickScannedLotItem = async function (item) {
    const product_lot = item.product_lot;
    const product_id = item.product_id;
    const order_item_id = item.order_item_id;
    const amountForLot = item.shippingItems.length;
    var onItemDeleted = { success: false };
    console.log("item", item);
    console.log("product_id", product_id);

    const itemIndex = findIndexForOrderItem(product_id);

    if (isEdit) {
      onItemDeleted = await deleteQuickScanShippingItemByLot({
        product_lot: product_lot,
        shipping_id: shipping.id,
        order_item_id: order_item_id,
        lot_quantity: item.shippingItems.length,
      });
    }
    console.log(onItemDeleted);

    if (onItemDeleted.success || !isEdit) {
      if (itemIndex >= 0) {
        console.log("itemIndex", itemIndex);
        const orderItems = [...values.orderItems];
        const orderItem = orderItems[itemIndex];

        const filteredItems = orderItem.shippingItems.filter((element) => {
          return element.product_lot !== product_lot;
        });
        const orderItemBeingEdited = {
          ...orderItem,
          shipped_amount: parseInt(orderItem.shipped_amount) - amountForLot,
          shippingItems: filteredItems,
        };
        var payload = {
          index: itemIndex,
          item: orderItemBeingEdited,
        };
        const newOrderItems = updateObjectInArray(orderItems, payload);
        setValues({ ...values, orderItems: newOrderItems });
        setEmptyModelOpen({
          childComponent: null,
          open: false,
        });
      }
    }
  };

  const makeDeleteShippingItemApiCall = async function (
    id,
    barcode,
    product_id
  ) {
    var onItemDeleted = { success: false };

    const itemIndex = findIndexForOrderItem(product_id);

    if (isEdit) {
      onItemDeleted = await deleteShippingItemApiCall(id);
    }

    if (onItemDeleted.success || !isEdit) {
      if (itemIndex >= 0) {
        const orderItems = [...values.orderItems];
        const orderItem = orderItems[itemIndex];

        const filteredItems = orderItem.shippingItems.filter((element) => {
          return element.shipping_barcode !== barcode;
        });
        const orderItemBeingEdited = {
          ...orderItem,
          shipped_amount: parseInt(orderItem.shipped_amount) - 1,
          shippingItems: filteredItems,
        };
        var payload = {
          index: itemIndex,
          item: orderItemBeingEdited,
        };
        const newOrderItems = updateObjectInArray(orderItems, payload);
        setValues({ ...values, orderItems: newOrderItems });
        setEmptyModelOpen({
          childComponent: null,
          open: false,
        });
      }
    }
  };

  const askDeleteShippingItem = function (item) {
    let text = item.product_name;
    if (item.is_lot) {
      text = item.product_lot;
    }

    setEmptyModelOpen({
      childComponent: (
        <DeleteConfirmationForm
          buttonMessage="Delete"
          confirmAction={() => {
            if (item.is_lot) {
              onDeleteQuickScannedLotItem(item);
            } else {
              makeDeleteShippingItemApiCall(
                item.id,
                item.shipping_barcode,
                item.product_id
              );
            }
          }}
          message={`Are you sure you want to delete ${text}?`}
          setOpen={setEmptyModelOpen}
        />
      ),
      open: true,
    });
  };

  return (
    <div className="grid grid-col-1 sm:grid-cols-3 gap-3 h-full">
      <div className="sm:col-span-2 card-custom p-3 order-2 sm:order-1">
        <div className="grid grid-cols-1 sm:grid-cols-4 gap-1">
          <div className="sm:col-span-3">
            <div className="space-x-2 mb-1">
              <label
                htmlFor="scanned_box_label"
                className={"text-sm block font-medium text-gray-900"}
              >
                Scan barcode
              </label>
            </div>
            <input
              placeholder="Scan box label"
              name="scanned_box_label"
              disabled={barcodeScanInProgress}
              ref={barcodeInputRef}
              onChange={handleInputChange}
              value={values.scanned_box_label}
              onKeyDown={(e) => {
                if (e.key === "Enter") handleBarcodeScan(e.target.value);
              }}
              type="search"
              className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-35-custom px-3 focus:outline-none focus:ring-sky-500 focus:border-sky-500 sm:text-sm"
            />
          </div>
          <div className="flex my-auto">
            <input
              id="quick_scan"
              aria-describedby="selectAllBox"
              name="quick_scan"
              type="checkbox"
              checked={values.quick_scan}
              className="mt-1 mr-2 h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-600"
              onClick={(e) => setQuickScan(e.target.checked)}
            />
            Quick Scan
          </div>
        </div>
        <OrderItemsForShipping
          askDeleteShippingItem={askDeleteShippingItem}
          loading={false}
          orderItems={values.orderItems}
          setEmptyModelOpen={setEmptyModelOpen}
          openQuickScanPopupWithFetchLatest={openQuickScanPopupWithFetchLatest}
          subItemsLoading={subItemsLoading}
          orderItemShipments={orderItemShipments}
          getOrderItemShipments={getOrderItemShipments}
        />
      </div>
      <div className="space-y-5 order-1 sm:order-2">
        <Accordion activeIndex={0}>
          <AccordionTab header="Shipping Information">
            <div className="card-custom p-3 space-y-2">
              <SelectMenu
                handleInputChange={(e) => {
                  handleInputChange(
                    convertToDefEventPara("order_id", e.target.value)
                  );
                  onFetchOrderItems(e.target.value.value);
                }}
                name="order_id"
                options={orders}
                defaultValue={isEdit ? shipping.order : null}
                title={"Order to ship"}
                disabled={isEdit}
                error
              />

              <SelectMenu
                handleInputChange={handleInputChange}
                name="created_by"
                options={employeeOptions}
                defaultValue={isEdit ? shipping.created_by : null}
                title={"Employee"}
                error={errors.created_by}
                showLabel={true}
              />

              <Input
                label="Invoice #"
                name="invoice_number"
                onChange={handleInputChange}
                value={values.invoice_number}
                labelOn={true}
                type="text"
                className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-35-custom px-3 focus:outline-none focus:ring-sky-500 focus:border-sky-500 sm:text-sm"
                error={errors.invoice_number}
              />

              <Input
                label="Seal #"
                name="seal_number"
                onChange={handleInputChange}
                value={values.seal_number}
                labelOn={true}
                type="text"
                className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-35-custom px-3 focus:outline-none focus:ring-sky-500 focus:border-sky-500 sm:text-sm"
                error={errors.seal_number}
              />

              <SelectMenu
                handleInputChange={handleInputChange}
                name="status"
                options={orderStatusOptions.filter(
                  (option) => option.value !== -1
                )}
                value={values.status}
                title={"Status"}
                error={errors.status}
                showLabel={true}
              />
              <div className="flex flex-shrink-0 py-4">
                <button
                  type="button"
                  className={classNames(
                    values.id > 0 ? null : "hidden",
                    "inline-flex justify-center rounded-md bg-gray-500 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-gray-600 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-gray-600"
                  )}
                  onClick={() => printShipping()}
                >
                  Print
                </button>
                <button
                  type="button"
                  className="ml-2 inline-flex justify-center rounded-md bg-main-purple px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-600 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                  onClick={createShipping}
                >
                  {values.id > 0 ? "Save" : "Submit"}
                </button>
                <button
                  type="button"
                  className={classNames(
                    values.id > 0 ? null : "hidden",
                    "ml-2 inline-flex justify-center rounded-md bg-green-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-green-700 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-green-700"
                  )}
                  onClick={exportOrderForNetSuite}
                >
                  Export
                </button>
                <button
                  type="button"
                  className={classNames(
                    values.id > 0 ? null : "hidden",
                    "ml-2 inline-flex justify-center rounded-md bg-red-500 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-red-600 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-red-600"
                  )}
                  onClick={deleteShipping}
                >
                  Delete
                </button>
              </div>
            </div>
          </AccordionTab>
        </Accordion>
      </div>
    </div>
  );
}
