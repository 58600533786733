import { useEffect } from "react";
import { CreateOrderViewModel } from "../../viewmodels/orders/CreateOrderViewModel";

import { connect } from "react-redux";

import * as actions from "../../store/actions/index";

import { withRouter } from "../../shared/utility";

import EmptyModal from "../../components/modals/EmptyModal";
import SlideOver from "../../components/slideOver/SlideOver";
import SimpleBanner from "../../components/SimpleBanner";
import CreateNewOrderForm from "../../components/forms/orders/CreateNewOrderForm";
import { useNavigate } from "react-router-dom";

function CreateOrder(props) {
  const { logout, addSnackbar } = props;
  const {
    customers,
    getCustomersForDropdown,
    getMyProducts,
    loading,
    showSimpleNotification,
    setEmptyModelOpen,
    setShowSimpleNotification,
    setSlideOver,
    slideOver,
    emptyModal,
    showErrorModal,
    createOrderApiCall,
    employeeOptions,
    getEmployeesForDropdown,
    navigateDestination,
  } = CreateOrderViewModel(logout, addSnackbar);

  const navigate = useNavigate();

  useEffect(() => {
    getEmployeesForDropdown([1, 2], [1, 6]);
    getCustomersForDropdown();
  }, []);

  useEffect(() => {
    if (navigateDestination !== null) {
      navigate(navigateDestination);
    }
  }, [navigateDestination]);

  return (
    <div>
      <SimpleBanner
        setShow={setShowSimpleNotification}
        showSimpleNotification={showSimpleNotification}
      />
      <div className="mt-2 px-4 sm:px-8">
        <div className="sm:items-center">
          <div className="sm:flex-auto">
            <h1 className="text-xl font-semibold leading-6 text-gray-900">
              Create Order
            </h1>
          </div>
          <div className="mt-5">
            <CreateNewOrderForm
              customers={customers}
              getMyProducts={getMyProducts}
              isEdit={false}
              loading={loading}
              setEmptyModelOpen={setEmptyModelOpen}
              showErrorModal={showErrorModal}
              createOrderApiCall={createOrderApiCall}
              setSlideOver={setSlideOver}
              employeeOptions={employeeOptions}
            />
          </div>
        </div>
      </div>
      <EmptyModal
        childComponent={emptyModal.childComponent}
        open={emptyModal.open}
        setOpen={setEmptyModelOpen}
      />
      <SlideOver
        childComponent={slideOver.childComponent}
        slideOver={slideOver}
        customClass="max-w-3xl"
        setSlideOver={setSlideOver}
      />
    </div>
  );
}

const mapStateToProps = (state) => {
  return {};
};

const mapDispatchToProps = (dispatch) => {
  return {
    logout: () => dispatch(actions.logout()),
    addSnackbar: (snackbar) => dispatch(actions.addSnackbar(snackbar)),
  };
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(CreateOrder)
);
