import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { InputText } from "primereact/inputtext";

export default function OrderProductsTable(props) {
  const { loading, values, onItemEditComplete, askDeleteOrderItem } = props;

  const itemActionsTemplate = (data) => {
    return (
      <div className="flex">
        <button
          onClick={() => askDeleteOrderItem(data)}
          className="ml-2 rounded-md bg-red-600 px-1.5 py-1.5 text-sm font-semibold text-white shadow-sm hover:bg-red-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-red-600"
        >
          Delete
        </button>
      </div>
    );
  };

  const textEditor = (options) => {
    return (
      <InputText
        type="text"
        value={options.value}
        onChange={(e) => options.editorCallback(e.target.value)}
        onKeyDown={(e) => e.stopPropagation()}
      />
    );
  };

  const onCellEditComplete = (e) => {
    let { rowData, rowIndex, newValue, field, originalEvent: event } = e;

    switch (field) {
      default:
        if (newValue.trim().length > 0) {
          rowData[field] = newValue;
          onItemEditComplete(rowData, rowIndex);
        } else {
          event.preventDefault();
        }
        break;
    }
  };

  const productDetailsBody = (rowData) => {
    return (
      <p>
        <span className="text-gray-900 font-semibold">
          {rowData.product_name}
        </span>
        <br />
        <span className="text-gray-700">PLU:{rowData.plu}</span>
      </p>
    );
  };

  const orderQuantityBody = (rowData) => {
    return <p>{rowData.quantity}</p>;
  };

  const calculcateTotalCases = (products) => {
    var total = 0;
    products.forEach((item) => {
      total += parseFloat(item.quantity);
    });

    return total;
  };

  return (
    <div className="mt-5">
      <div className="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
        <div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
          {loading ? (
            <div>
              <div className="border h-10 shadow rounded-md p-4 w-full mx-auto">
                <div className="animate-pulse flex space-x-4">Loading</div>
              </div>
            </div>
          ) : (
            <div className="overflow-hidden">
              <DataTable
                value={values.products}
                tableClassName="min-w-full bg-white divide-y divide-gray-300 text-sm create-order-inventory-table-parent"
                cellClassName="p-2 bg-white border-1-bottom-gray text-sm"
                editMode="cell"
              >
                <Column header="Product" body={productDetailsBody} />
                <Column header="Description" field="description_en" />
                <Column
                  field="quantity"
                  header="Quantity"
                  style={{ textAlign: "right" }}
                  editor={(options) => textEditor(options)}
                  onCellEditComplete={onCellEditComplete}
                  body={orderQuantityBody}
                />

                <Column
                  header=""
                  style={{ textAlign: "right" }}
                  body={itemActionsTemplate}
                />
              </DataTable>
              <div className="float-right py-5">
                <p className="font-bold">
                  Total Cases: {calculcateTotalCases(values.products)}
                </p>
              </div>
            </div>
          )}
          {/* <div className="w-full text-right mt-4">
              <p className="font-bold">
                Subtotal: {order.subtotal_dollar_amount}
              </p>
              <p className="font-bold">HST: 13%</p>
              <p className="font-bold">Subtotal: {order.total_dollar_amount}</p>
            </div> */}
        </div>
      </div>
    </div>
  );
}
