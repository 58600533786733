import { Link } from "react-router-dom";
import { formatDate, orderStatusOptions } from "../../../shared/utility";
import TextWithLabel from "../../controls/TextWithLabel";
import ManufactureRecipeItemsTable from "../production/components/ManufactureRecipeItemsTable";
import ManufactureIngredientsTable from "../production/components/ManufactureIngredientsTable";
import Orders from "./orders/Orders";

export default function TraceShippingBarcode(props) {
  const { product, openReceivingItemInformation } = props;

  const convertStatus = (status) => {
    const statusOption = orderStatusOptions.filter((el) => el.value === status);

    return statusOption.length === 1
      ? { text: statusOption[0].label, type: statusOption[0].type }
      : { text: orderStatusOptions[0].label, type: orderStatusOptions[0].type };
  };

  return (
    <div className="flow-root overflow-x-auto">
      <div className="inline-block min-w-full py-2 align-middle">
        <div className="grid grid-cols-2 gap-4 mt-2">
          <div className="col-span-2">
            <h3 className="text-lg mb-2">Production history</h3>
            <div className="overflow-hidden card-custom p-4 flex">
              <div className="grid grid-cols-4 gap-4 mt-2 flex-grow">
                <TextWithLabel
                  label="Product Name"
                  text={product.produced_product.product_name}
                />
                <TextWithLabel
                  label="PLU"
                  text={product.produced_product.plu}
                />
                <TextWithLabel
                  label="Description"
                  text={product.produced_product.description_en}
                />
                <TextWithLabel
                  label="Lot Number"
                  text={product.produced_product.new_lot_number}
                />
                <TextWithLabel
                  label="Production Date"
                  text={formatDate(product.produced_product.production_date)}
                />
                <TextWithLabel
                  label="Supervisor"
                  text={product.produced_product.supervisor.label}
                />
                <TextWithLabel
                  label="Certified By"
                  text={product.produced_product.certification_name}
                />
                <TextWithLabel label="Claims(s)" text="" />
                <TextWithLabel
                  label="Production Room"
                  text={product.produced_product.production_room}
                />
                <TextWithLabel
                  label="Production Room Temp C&deg;"
                  text={product.produced_product.production_room_temperature}
                />
                <TextWithLabel
                  label="Raw Product Temp C&deg;"
                  text={product.produced_product.raw_product_temperature}
                />
              </div>
              <div>
                <Link
                  className="ml-5 rounded-md bg-main-purple px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-600 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                  to={"/manufactured-products/" + product.produced_product.id}
                >
                  Details
                </Link>
              </div>
            </div>
          </div>
          <div className="col-span-2">
            {product.produced_product.selectedRecipe.recipe ? (
              <ManufactureRecipeItemsTable
                selectedRecipe={product.produced_product.selectedRecipe}
                values={product.produced_product}
                openReceivingItemInformation={openReceivingItemInformation}
                isTraceability={true}
              />
            ) : (
              <ManufactureIngredientsTable
                productAssemblyItems={product.produced_product.ingredients}
                openReceivingItemInformation={openReceivingItemInformation}
                isTraceability={true}
              />
            )}
          </div>
          <div className="col-span-2">
            <h3 className="text-lg mb-2">Packaging history</h3>
            <div className="overflow-hidden card-custom p-4">
              <div className="grid grid-cols-4 gap-4 mt-2">
                <TextWithLabel
                  label="Brand"
                  text={product.packaged_product.brand_name}
                />
                <TextWithLabel
                  label="Packaged product"
                  text={product.packaged_product.product_name}
                />
                <TextWithLabel
                  label="Packaging date"
                  text={formatDate(product.packaged_product.packaging_date)}
                />
                <TextWithLabel
                  label="Description"
                  text={product.packaged_product.description_en}
                />
                <TextWithLabel
                  label="Best before date"
                  text={product.packaged_product.best_before_date}
                />
                <TextWithLabel
                  label="Packing size"
                  text={product.packaged_product.packing_size + " kg"}
                />
                <TextWithLabel
                  label="Shelf life"
                  text={product.packaged_product.shelf_life}
                />
                <TextWithLabel
                  label="Room Temp C&deg;"
                  text={product.packaged_product.room_temperature}
                />
                <TextWithLabel
                  label="Product Temp C&deg;"
                  text={product.packaged_product.product_temperature}
                />
                <TextWithLabel
                  label="Passed from metal detector"
                  text={
                    product.packaged_product.passed_from_metal_detector === 1
                      ? "Yes"
                      : "No"
                  }
                />
                <TextWithLabel
                  label="Packaging Material 1"
                  text={product.packaged_product.packaging_material_1}
                />
                <TextWithLabel
                  label="Packaging Material 2"
                  text={product.packaged_product.packaging_material_2}
                />
              </div>
            </div>
          </div>
          <div className="col-span-2">
            <h3 className="text-lg mb-2">Order history</h3>
            {product.orders ? (
              product.orders.length > 0 ? (
                <div className="grid grid-cols-2 gap-4 mt-2">
                  {product.orders.map((order) => (
                    <div className="overflow-hidden card-custom p-4 flex">
                      <div className="grid grid-cols-3 gap-4 flex-grow">
                        <TextWithLabel label="Customer" text={order.customer} />
                        <TextWithLabel label="PO #" text={order.po_number} />
                        <TextWithLabel
                          label="Date"
                          text={formatDate(order.order_date)}
                        />
                        <TextWithLabel label="Quantity" text={1} />
                        <TextWithLabel
                          label="status"
                          text={convertStatus(order.status).text}
                        />
                        <TextWithLabel
                          label="Shipped date"
                          text={order.shipped_date}
                        />
                      </div>
                      <div>
                        <Link
                          className="ml-5 rounded-md bg-main-purple px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-600 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                          to={"/orders/" + order.id}
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          View
                        </Link>
                      </div>
                    </div>
                  ))}
                </div>
              ) : (
                <div className="overflow-hidden card-custom p-4">
                  <div className="grid grid-cols-4 gap-4 mt-2">
                    <TextWithLabel label="Sale status" text="Not sold" />
                  </div>
                </div>
              )
            ) : null}
          </div>
        </div>
      </div>
    </div>
  );
}
