import React from "react";
import { Link } from "react-router-dom";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { formatDate } from "../../../../shared/utility";

export default function TraceManufacturedProductsTable(props) {
  const { product } = props;

  const itemActionTemplate = (rowData) => {
    return (
      <div className="ml-10">
        <Link
          className="ml-5 rounded-md bg-main-purple px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-600 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
          to={"/manufactured-products/" + rowData.id}
          target="_blank"
          rel="noopener noreferrer"
        >
          View
        </Link>
      </div>
    );
  };

  const productNameTemplate = (rowData) => {
    return (
      <p>
        {rowData.product_name} <br />
        {rowData.plu}
      </p>
    );
  };

  const productionDateTemplate = (rowData) => {
    return formatDate(rowData.production_date);
  };

  function amountUsed(rowData) {
    return (
      <p>
        {rowData.amount_used} {product.unit_of_measure}
      </p>
    );
  }

  return (
    <div className="flow-root">
      <div className="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
        <div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
          <div className="overflow-hidden card-custom bg-white">
            <DataTable
              value={product.manufactured_products}
              dataKey="id"
              headertableClassName="bg-white"
              tableClassName="min-w-full bg-white divide-y divide-gray-300"
              stripedRows
            >
              <Column header="Product Name" body={productNameTemplate} />
              <Column
                header="Production Date"
                body={productionDateTemplate}
                style={{ width: "10%" }}
              />
              <Column field="description_en" header="Description" />
              <Column field="new_lot_number" header="Lot Number" />
              <Column
                header="Amount Used"
                body={amountUsed}
                style={{ width: "10%" }}
              />
              <Column field="certification_name" header="Certification" />
              <Column
                field="supervisor_name"
                header="Supervisor Name"
                style={{ width: "10%" }}
              />
              <Column header="" body={itemActionTemplate} />
            </DataTable>
          </div>
        </div>
      </div>
    </div>
  );
}
