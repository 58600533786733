import { AdjustmentsHorizontalIcon } from "@heroicons/react/24/outline";

import { classNames } from "../../shared/utility";

export default function FilterDropdown(props) {
  const {
    actionCall,
    filterComponents,
    resetFilters,
    showFilters,
    setShowFilters,
    title,
  } = props;

  return (
    <div className="relative -ml-px block">
      <button
        className="ml-0 sm:ml-2 relative inline-flex items-center rounded-md bg-white px-3 py-2 text-sm text-gray-900 font-semibold ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus:z-10"
        onClick={() => setShowFilters(true)}
      >
        <AdjustmentsHorizontalIcon
          className="h-5 w-5 mr-2"
          aria-hidden="true"
        />
        {title ? title : "Filter"}
      </button>

      <div
        className={classNames(
          showFilters ? null : "hidden",
          "absolute right-0 z-10 -mr-1 mt-2 w-96 origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none divide-y divide-gray-300"
        )}
      >
        <div className="p-3 flex justify-between">
          <p className="text-sm text-gray-700 font-semibold">
            {title ? title : "Filter"}
          </p>
          <button onClick={() => setShowFilters(false)}>X</button>
        </div>
        {filterComponents.map((item, index) => (
          <div key={index}>{item}</div>
        ))}
        <div className="p-3 flex justify-between">
          <button
            onClick={() => resetFilters()}
            className="rounded-md px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
          >
            Reset All
          </button>
          <button
            onClick={() => {
              setShowFilters(false);
              actionCall();
            }}
            className="ml-5 rounded-md bg-main-purple px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-600 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
          >
            Apply
          </button>
        </div>
      </div>
    </div>
  );
}
