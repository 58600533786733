import { useEffect } from "react";
import { CreateShippingViewModel } from "../../viewmodels/shipping/CreateShippingViewModel";

import { connect } from "react-redux";

import * as actions from "../../store/actions/index";

import { withRouter } from "../../shared/utility";

import EmptyModal from "../../components/modals/EmptyModal";
import SlideOver from "../../components/slideOver/SlideOver";
import SimpleBanner from "../../components/SimpleBanner";
import CreateNewShippingForm from "../../components/forms/shipping/CreateNewShippingForm";

function CreateShipping(props) {
  const { logout } = props;
  const {
    orders,
    getOpenOrdersForDropdown,
    onFetchOrderItems,
    loading,
    showSimpleNotification,
    setEmptyModelOpen,
    setShowSimpleNotification,
    setSlideOver,
    slideOver,
    emptyModal,
    showErrorModal,
    createShippingApiCall,
    shipping,
    onFetchPackagedItemByBarcode,
    employeeOptions,
    getEmployeesForDropdown,
    fetchPackagedItemsForQuickScan,
    getOrderItemsOnly,
  } = CreateShippingViewModel(logout);

  useEffect(() => {
    getEmployeesForDropdown([1, 2], [1, 5]);
    getOpenOrdersForDropdown();
  }, []);

  return (
    <div>
      <SimpleBanner
        setShow={setShowSimpleNotification}
        showSimpleNotification={showSimpleNotification}
      />
      <div className="mt-2 lg:px-8">
        <div className="sm:items-center">
          <div className="sm:flex-auto">
            <h1 className="text-xl font-semibold leading-6 text-gray-900">
              Create Shipping
            </h1>
          </div>
          <div className="mt-5">
            <CreateNewShippingForm
              orders={orders}
              onFetchOrderItems={onFetchOrderItems}
              isEdit={false}
              loading={loading}
              setEmptyModelOpen={setEmptyModelOpen}
              showErrorModal={showErrorModal}
              createShippingApiCall={createShippingApiCall}
              shipping={shipping}
              onFetchPackagedItemByBarcode={onFetchPackagedItemByBarcode}
              addPackagedItemToOrderItem={() => {}}
              employeeOptions={employeeOptions}
              deleteShipping={{}}
              fetchPackagedItemsForQuickScan={fetchPackagedItemsForQuickScan}
              getOrderItemsOnly={getOrderItemsOnly}
            />
          </div>
        </div>
      </div>
      <EmptyModal
        childComponent={emptyModal.childComponent}
        open={emptyModal.open}
        setOpen={setEmptyModelOpen}
      />
      <SlideOver
        childComponent={slideOver.childComponent}
        slideOver={slideOver}
        customClass="max-w-3xl"
        setSlideOver={setSlideOver}
      />
    </div>
  );
}

const mapStateToProps = (state) => {
  return {};
};

const mapDispatchToProps = (dispatch) => {
  return {
    logout: () => dispatch(actions.logout()),
  };
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(CreateShipping)
);
