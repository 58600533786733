import { useState } from "react";

import { makePostApiCall } from "../../shared/makeApiCall";
import ModelErrorComponent from "../../components/modals/ModelErrorComponent";
import { BannerNotificationType } from "../../components/SimpleBanner";
import { SnackbarData, SnackbarType } from "../../components/Snackbar";
import { generateUniqueNumber } from "../../shared/utility";

export function CreateOrderViewModel(logout, addSnackbar) {
  const [customers, setCustomers] = useState([]);
  const [employeeOptions, setEmployeeOptions] = useState([]);
  const [emptyModal, setEmptyModelOpen] = useState({
    open: false,
    childComponent: null,
  });
  const [loading, setLoading] = useState(false);
  const [showSimpleNotification, setShowSimpleNotification] = useState({
    show: false,
    message: null,
    title: null,
    type: BannerNotificationType.Success,
  });
  const [slideOver, setSlideOver] = useState({
    childComponent: null,
    open: false,
  });
  const [navigateDestination, setNavigateDestination] = useState(null);

  const getCustomersForDropdown = async function () {
    let callResult = await makePostApiCall(`getCustomersForDropdown`);
    if (callResult.success) {
      setCustomers(callResult.data);
    } else {
      if (callResult.errorStatus === 401) {
        logout();
      }
    }
  };

  const getMyProducts = async function (search, page) {
    const payload = {
      page: page,
      search: search,
    };
    let callResult = await makePostApiCall(`fetchProductsForDropdown`, payload);
    if (callResult.success) {
      return callResult.data;
    } else {
      if (callResult.errorStatus === 401) {
        logout();
      }
    }
  };

  const showErrorModal = (title, subtitle) => {
    setEmptyModelOpen({
      childComponent: (
        <ModelErrorComponent
          setOpen={setEmptyModelOpen}
          subtitle={subtitle}
          title={title}
        />
      ),
      open: true,
    });
  };

  const createOrderApiCall = async function (newOrder) {
    let callResult = await makePostApiCall(`createOrder`, newOrder);
    if (callResult.success) {
      addSnackbar(
        new SnackbarData(
          generateUniqueNumber(),
          "Order created",
          SnackbarType.Success
        )
      );

      setNavigateDestination(`/orders/${callResult.data.id}`);
    } else {
      if (callResult.errorStatus === 401) {
        logout();
      }
    }
  };

  const getEmployeesForDropdown = async function (type, department) {
    const payload = { department: department, type: type };
    let callResult = await makePostApiCall(`employeesForDropdown`, payload);

    if (callResult.success) {
      setEmployeeOptions(callResult.data);
    } else {
      if (callResult.errorStatus === 401) {
        logout();
      }
    }
  };

  return {
    customers,
    getCustomersForDropdown,
    getMyProducts,
    loading,
    showSimpleNotification,
    setEmptyModelOpen,
    setShowSimpleNotification,
    setSlideOver,
    slideOver,
    emptyModal,
    showErrorModal,
    createOrderApiCall,
    employeeOptions,
    getEmployeesForDropdown,
    navigateDestination,
  };
}
