import { useEffect, useState } from "react";
import { connect } from "react-redux";

import * as actions from "../../store/actions/index";

import { formatDateWithTime, withRouter } from "../../shared/utility";

import EmptyModal from "../../components/modals/EmptyModal";
import SimpleBanner from "../../components/SimpleBanner";
import { TemperatureSensorsViewModel } from "../../viewmodels/qaReports/TemperatureSensorsViewModel";

import { Link } from "react-router-dom";

import thermometer_temperature from "../../assets/label-assets/thermometer-temperature.svg";
import humidity_icon from "../../assets/label-assets/humidity-icon.svg";
import { EllipsisHorizontalIcon } from "@heroicons/react/20/solid";
import { Menu, MenuButton, MenuItem, MenuItems } from "@headlessui/react";

function TemperatureSensors(props) {
  const { logout } = props;
  const [currentTime, setCurrentTime] = useState("");

  const {
    emptyModal,
    loading,
    setEmptyModelOpen,
    setShowSimpleNotification,
    showSimpleNotification,
    getTemperatureSensors,
    sensors,
  } = TemperatureSensorsViewModel(logout);

  useEffect(() => {
    getTemperatureSensors();
  }, []);

  useEffect(() => {
    const interval = setInterval(() => {
      window.location.reload();
    }, 60000);

    return () => clearInterval(interval);
  }, []);

  useEffect(() => {
    const updateTime = () => {
      const now = new Date();
      const formattedTime = now.toLocaleString("en-US", {
        month: "short",
        day: "2-digit",
        year: "numeric",
        hour: "numeric",
        minute: "2-digit",
        hour12: true,
      });
      setCurrentTime(formattedTime);
    };

    updateTime(); // Set time on load
    const interval = setInterval(updateTime, 1000); // Update every second (optional)

    return () => clearInterval(interval); // Cleanup interval
  }, []);

  return (
    <div>
      <SimpleBanner
        setShow={setShowSimpleNotification}
        showSimpleNotification={showSimpleNotification}
      />
      <div className="hide-for-print">
        <div className="px-0 sm:px-6 lg:px-8">
          <div className="sm:flex">
            <h1 className="text-lg font-semibold leading-6 text-gray-900">
              Temperature Sensors
            </h1>
            <p className="ml-auto text-2xl font-semibold leading-6 text-gray-900">
              {currentTime}
            </p>
          </div>
          <div className="mt-4 grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-3">
            {sensors.data.map((sensor) => (
              <div className="card-custom p-4 relative">
                {sensor.location ? null : (
                  <p className="font-semibold text-red-600 mb-5">
                    No data for this sensor at this location yet...
                  </p>
                )}
                <div className="flex justify-between">
                  <div className="flex gap-2">
                    <div className="rounded-md bg-pink-400 p-3">
                      <img
                        src={thermometer_temperature}
                        className="h-6 w-6 text-white"
                      />
                    </div>
                    <div>
                      <p>{sensor.temperature}&deg;C</p>
                      <p className="">Temperature</p>
                    </div>
                  </div>
                  <div className="flex gap-2">
                    <div className="rounded-md bg-purple-400 p-3">
                      <img src={humidity_icon} className="h-6 w-6 text-white" />
                    </div>
                    <div>
                      <p>{sensor.humidity}%</p>
                      <p className="">Humidity</p>
                    </div>
                  </div>
                  <div>
                    <Menu as="div" className="relative ml-auto">
                      <MenuButton className="-m-2.5 block p-2.5 text-gray-400 hover:text-gray-500">
                        <span className="sr-only">Open options</span>
                        <EllipsisHorizontalIcon
                          aria-hidden="true"
                          className="h-5 w-5"
                        />
                      </MenuButton>
                      <MenuItems
                        transition
                        className="absolute right-0 z-10 mt-0.5 w-32 origin-top-right rounded-md bg-white py-2 shadow-lg ring-1 ring-gray-900/5 transition focus:outline-none data-[closed]:scale-95 data-[closed]:transform data-[closed]:opacity-0 data-[enter]:duration-100 data-[leave]:duration-75 data-[enter]:ease-out data-[leave]:ease-in"
                      >
                        <MenuItem>
                          <Link
                            className="block px-3 py-1 text-sm leading-6 text-gray-900 data-[focus]:bg-gray-50"
                            to={`/temperature-sensors/${sensor.sensor_id}`}
                          >
                            View
                          </Link>
                        </MenuItem>
                      </MenuItems>
                    </Menu>
                  </div>
                </div>
                <div className="mt-4">
                  <p>
                    <span className="font-bold">Name:</span> {sensor.name}
                  </p>
                  <div className="flex justify-between">
                    <p>
                      <span className="font-bold">Location:</span>{" "}
                      {sensor.location}
                    </p>
                  </div>
                  <p>
                    <span className="font-bold">Last seen date:</span>{" "}
                    {formatDateWithTime(sensor.created_date)}
                  </p>
                  <p>
                    <span className="font-bold">Sensor id:</span>{" "}
                    {sensor.sensor_id}
                  </p>
                  <p className="font-bold">
                    Status:{" "}
                    <span
                      className={
                        sensor.active ? "text-green-600" : "text-red-600"
                      }
                    >
                      {sensor.status_text}
                    </span>
                  </p>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
      <EmptyModal
        childComponent={emptyModal.childComponent}
        open={emptyModal.open}
        setOpen={setEmptyModelOpen}
      />
    </div>
  );
}

const mapStateToProps = (state) => {
  return {};
};

const mapDispatchToProps = (dispatch) => {
  return {
    logout: () => dispatch(actions.logout()),
  };
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(TemperatureSensors)
);
