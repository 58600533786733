import { CalendarIcon, ChevronDownIcon } from "@heroicons/react/20/solid";

import { DateRangePicker } from "react-date-range";
import "react-date-range/dist/styles.css";
import "react-date-range/dist/theme/default.css";
import { subDays, endOfDay } from "date-fns";
import { useEffect, useState } from "react";
import { classNames } from "../../shared/utility";

export default function DateRangeFilter(props) {
  const {
    applyChanges,
    onChange,
    startDate,
    endDate,
    maxDate,
    showDates,
    setShowDates,
    rangeSummary,
  } = props;

  const defaultDateState = [
    {
      startDate: subDays(new Date(), 7),
      endDate: new Date(),
      key: "selection",
    },
  ];
  const [state, setState] = useState(defaultDateState);

  useEffect(() => {
    setState([
      {
        startDate: new Date(startDate),
        endDate: new Date(endDate),
        key: "selection",
      },
    ]);
  }, []);

  const handleDateChange = (ranges) => {
    let { startDate, endDate } = ranges.selection;

    // 👇 If both are the same day, push endDate to 23:59:59
    if (startDate.toDateString() === endDate.toDateString()) {
      endDate = endOfDay(endDate);
    }

    onChange(ranges.selection.startDate, endDate);

    setState([
      {
        ...ranges.selection,
        endDate,
      },
    ]);
  };

  return (
    <div className="relative -ml-px block">
      <button
        className="relative inline-flex items-center rounded-md bg-white px-3 py-2 text-sm text-gray-900 font-semibold ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus:z-10"
        onClick={() => setShowDates(!showDates)}
      >
        <CalendarIcon className="h-5 w-5 mr-2" aria-hidden="true" />
        {rangeSummary}
        <ChevronDownIcon className="h-5 w-5 ml-2" aria-hidden="true" />
      </button>

      <div
        className={classNames(
          showDates ? null : "hidden",
          "absolute right-0 z-10 mt-2 w-100 origin-top-right rounded-md bg-gray-100 shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none divide-y divide-gray-300 p-1"
        )}
      >
        <div className="">
          <DateRangePicker
            onChange={handleDateChange}
            showSelectionPreview={true}
            moveRangeOnFirstSelection={false}
            months={1}
            ranges={state}
            direction="vertical"
            maxDate={maxDate}
          />
        </div>
        <div className="p-3 flex justify-end gap-2">
          <button
            onClick={() => {
              setShowDates(false);
            }}
            className="rounded-md px-3 py-2 text-sm font-semibold text-gray-900 bg-white shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
          >
            Close
          </button>
          <button
            onClick={() => {
              applyChanges();
              setShowDates(false);
            }}
            className="rounded-md bg-main-purple px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-600 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
          >
            Apply
          </button>
        </div>
      </div>
    </div>
  );
}
