import { useState } from "react";

import { makePostApiCall } from "../../shared/makeApiCall";
import ModelErrorComponent from "../../components/modals/ModelErrorComponent";
import { SnackbarData, SnackbarType } from "../../components/Snackbar";
import { generateUniqueNumber } from "../../shared/utility";

export function OrderDetailsViewModel(orderId, logout, addSnackbar) {
  const [customers, setCustomers] = useState([]);
  const [employeeOptions, setEmployeeOptions] = useState([]);
  const [emptyModal, setEmptyModelOpen] = useState({
    open: false,
    childComponent: null,
  });
  const [order, setOrder] = useState(null);
  const [loading, setLoading] = useState(false);
  const [products, setProducts] = useState({ data: [] });
  const [slideOver, setSlideOver] = useState({
    childComponent: null,
    open: false,
  });

  const getOrderDetails = async function () {
    let callResult = await makePostApiCall(`fetchOrder/${orderId}`);
    if (callResult.success) {
      setOrder(callResult.data);
    } else {
      if (callResult.errorStatus === 401) {
        logout();
      }
      showErrorModal(callResult.data.message);
    }
  };

  const getCustomersForDropdown = async function () {
    let callResult = await makePostApiCall(`getCustomersForDropdown`);
    if (callResult.success) {
      setCustomers(callResult.data);
      return callResult;
    } else {
      if (callResult.errorStatus === 401) {
        logout();
      }
      showErrorModal(callResult.data.message);
    }
  };

  const getMyProducts = async function (search, page) {
    const payload = {
      page: page,
      search: search,
    };
    let callResult = await makePostApiCall(`fetchProductsForDropdown`, payload);
    if (callResult.success) {
      return callResult.data;
    } else {
      if (callResult.errorStatus === 401) {
        logout();
      }
      showErrorModal(callResult.data.message);
    }
  };

  const showErrorModal = (title, subtitle) => {
    setEmptyModelOpen({
      childComponent: (
        <ModelErrorComponent
          setOpen={setEmptyModelOpen}
          subtitle={subtitle}
          title={title}
        />
      ),
      open: true,
    });
  };

  const updateOrderApiCall = async function (newOrder) {
    let callResult = await makePostApiCall(`updateOrder/${orderId}`, newOrder);
    if (callResult.success) {
      addSnackbar(
        new SnackbarData(
          generateUniqueNumber(),
          callResult.data.message,
          SnackbarType.Success
        )
      );
    } else {
      if (callResult.errorStatus === 401) {
        logout();
      }
      showErrorModal(callResult.data.message);
    }
  };

  const onUpdateOrderItem = async function (id, newQuantity) {
    let callResult = await makePostApiCall(`updateOrderItem/${id}`, {
      quantity: newQuantity,
    });
    if (callResult.success) {
      addSnackbar(
        new SnackbarData(
          generateUniqueNumber(),
          callResult.data.message,
          SnackbarType.Success
        )
      );
    } else {
      if (callResult.errorStatus === 401) {
        logout();
      }
      showErrorModal(callResult.data.message);
    }
    return callResult;
  };

  const deleteOrderItemApiCall = async function (id) {
    let callResult = await makePostApiCall(`deleteOrderItem/${id}`);

    if (callResult.success) {
      setEmptyModelOpen({
        open: false,
        childComponent: null,
      });

      addSnackbar(
        new SnackbarData(
          generateUniqueNumber(),
          callResult.data.message,
          SnackbarType.Success
        )
      );
    } else {
      if (callResult.errorStatus === 401) {
        logout();
      }
      showErrorModal(callResult.data.message);
    }
    return callResult;
  };

  const addProductToOrderApiCall = async function (orderId, payload) {
    let callResult = await makePostApiCall(
      `addProductToOrder/${orderId}`,
      payload
    );

    if (callResult.success) {
      addSnackbar(
        new SnackbarData(
          generateUniqueNumber(),
          "Product added!",
          SnackbarType.Success
        )
      );
    } else {
      if (callResult.errorStatus === 401) {
        logout();
      }
      showErrorModal(callResult.data.message);
    }
    return callResult;
  };

  const getEmployeesForDropdown = async function (type, department) {
    const payload = { department: department, type: type };
    let callResult = await makePostApiCall(`employeesForDropdown`, payload);

    if (callResult.success) {
      setEmployeeOptions(callResult.data);
    } else {
      if (callResult.errorStatus === 401) {
        logout();
      }
    }
  };

  const printOrder = async function () {
    let callResult = await makePostApiCall(`printOrder/${orderId}`);

    if (callResult.success) {
      window.open(callResult.data.location, "_blank");
    } else {
      if (callResult.errorStatus === 401) {
        logout();
      }
    }
  };

  return {
    customers,
    getCustomersForDropdown,
    getMyProducts,
    loading,
    products,
    setEmptyModelOpen,
    setSlideOver,
    slideOver,
    emptyModal,
    showErrorModal,
    updateOrderApiCall,
    order,
    getOrderDetails,
    onUpdateOrderItem,
    deleteOrderItemApiCall,
    addProductToOrderApiCall,
    employeeOptions,
    getEmployeesForDropdown,
    printOrder,
  };
}
