import { useState } from "react";

import { makeGetApiCall, makePostApiCall } from "../../shared/makeApiCall";
import { BannerNotificationType } from "../../components/SimpleBanner";
import ModelErrorComponent from "../../components/modals/ModelErrorComponent";

export function CreateShippingViewModel(logout) {
  const [employeeOptions, setEmployeeOptions] = useState([]);
  const [orders, setOrders] = useState([]);
  const [emptyModal, setEmptyModelOpen] = useState({
    open: false,
    childComponent: null,
  });
  const [loading, setLoading] = useState(false);
  const [showSimpleNotification, setShowSimpleNotification] = useState({
    show: false,
    message: null,
    title: null,
    type: BannerNotificationType.Success,
  });
  const [slideOver, setSlideOver] = useState({
    childComponent: null,
    open: false,
  });
  const [shipping, setShipping] = useState({
    id: 0,
    user_id: 0,
    orderItems: [],
    order_picked: 0,
  });

  const getOpenOrdersForDropdown = async function () {
    let callResult = await makePostApiCall(`getOpenOrdersForDropdown`);
    if (callResult.success) {
      setOrders(callResult.data);
    } else {
      if (callResult.errorStatus === 401) {
        logout();
      }
    }
  };

  const onFetchOrderItems = async function (id) {
    let callResult = await makePostApiCall(`fetchOrder/${id}`);
    if (callResult.success) {
      const order = callResult.data;
      setShipping({
        ...shipping,
        order_id: order.id,
        customer: order.customer,
        orderItems: [...order.products],
      });
    } else {
      if (callResult.errorStatus === 401) {
        logout();
      }
    }
  };

  const showErrorModal = (title, subtitle) => {
    setEmptyModelOpen({
      childComponent: (
        <ModelErrorComponent
          setOpen={setEmptyModelOpen}
          subtitle={subtitle}
          title={title}
        />
      ),
      open: true,
    });
  };

  const createShippingApiCall = async function (newShipping) {
    let callResult = await makePostApiCall(`createShipping`, newShipping);
    if (callResult.success) {
      window.location = `/shippings/${callResult.data.id}`;
    } else {
      if (callResult.errorStatus === 401) {
        logout();
      }
    }
  };

  const onFetchPackagedItemByBarcode = async function (scannedBarcode) {
    let callResult = await makePostApiCall(
      `fetchPackagedItemByBarcode/${scannedBarcode}`
    );
    if (callResult.success) {
    } else {
      if (callResult.errorStatus === 401) {
        logout();
      }
    }
    return callResult;
  };

  const fetchPackagedItemsForQuickScan = async function (payload) {
    let callResult = await makePostApiCall(
      `fetchPackagedItemsForQuickScan`,
      payload
    );
    if (callResult.success) {
    } else {
      if (callResult.errorStatus === 401) {
        logout();
      }
    }
    return callResult;
  };

  const getEmployeesForDropdown = async function (type, department) {
    const payload = { department: department, type: type };
    let callResult = await makePostApiCall(`employeesForDropdown`, payload);

    if (callResult.success) {
      setEmployeeOptions(callResult.data);
    } else {
      if (callResult.errorStatus === 401) {
        logout();
      }
    }
  };

  const getOrderItemsOnly = async function () {
    let callResult = await makeGetApiCall(
      `getOrderItemsOnly/${shipping.order_id}`
    );
    if (callResult.success) {
    } else {
      if (callResult.errorStatus === 401) {
        logout();
      }
    }
    return callResult;
  };

  return {
    orders,
    getOpenOrdersForDropdown,
    onFetchOrderItems,
    loading,
    showSimpleNotification,
    setEmptyModelOpen,
    setShowSimpleNotification,
    setSlideOver,
    slideOver,
    emptyModal,
    showErrorModal,
    createShippingApiCall,
    shipping,
    onFetchPackagedItemByBarcode,
    setShipping,
    employeeOptions,
    getEmployeesForDropdown,
    fetchPackagedItemsForQuickScan,
    getOrderItemsOnly,
  };
}
