import { useState } from "react";

import { makePostApiCall } from "../../shared/makeApiCall";
import { BannerNotificationType } from "../../components/SimpleBanner";
import DeleteConfirmationForm from "../../components/forms/DeleteConfirmationForm";
import FilterSingleSelectDropdown from "../../components/filterDropdown/FilterSingleSelectDropdown";
import DateRangeFilter from "../../components/filterDropdown/DateRangeFilter";
import FilterInput from "../../components/filterDropdown/FilterInput";
import subDays from "date-fns/subDays";
import ModelErrorComponent from "../../components/modals/ModelErrorComponent";

const reportTypeOptions = [{ value: 1, label: "Production Report" }];

export function ManufacturedProductsViewModel(logout) {
  const [analytics, setAnalytics] = useState({
    range: null,
    stats: [],
    loading: true,
  });
  const [showDates, setShowDates] = useState(false);
  const [filters, setFilters] = useState({
    product: { value: -1, label: "All Products" },
    supervisor: { value: -1, label: "All Employees" },
    employee1: { value: -1, label: "All Employees" },
    employee2: { value: -1, label: "All Employees" },
    certification: { value: -1, label: "All Certifications" },
    recipe: { value: -1, label: "All Recipes" },
    grandTotal: null,
    startDate: subDays(new Date(), 7),
    endDate: new Date(),
    product_lot: null,
  });
  const [reportFilters, setReportFilters] = useState({
    type: { value: -1, label: "Select report" },
    startDate: null,
    endDate: null,
  });

  const [certifications, setCertifications] = useState([]);
  const [employeeOptions, setEmployeeOptions] = useState([]);
  const [supervisorOptions, setSupervisorOptions] = useState([]);
  const [emptyModal, setEmptyModelOpen] = useState({
    open: false,
    childComponent: null,
  });
  const [loading, setLoading] = useState(false);
  const [productionHistories, setProductionHistories] = useState({ data: [] });
  const [recipes, setRecipes] = useState([]);
  const [showFilters, setShowFilters] = useState(false);
  const [showReports, setShowReports] = useState(false);
  const [showSimpleNotification, setShowSimpleNotification] = useState({
    show: false,
    message: null,
    title: null,
    type: BannerNotificationType.Success,
  });
  const [slideOver, setSlideOver] = useState({
    childComponent: null,
    open: false,
  });
  const [reportInProgress, setReportInProgress] = useState(false);
  const [productsForDropdown, setProductsForDropdown] = useState([]);

  const getManufacturedProducts = async function (search, page, filterArg) {
    setLoading(true);
    const payload = {
      page: page,
      filters: { ...filterArg, search: search },
    };
    getAnalytics(search, filterArg);
    let callResult = await makePostApiCall("manufacturedProducts", payload);

    if (callResult.success) {
      setProductionHistories(callResult.data);
    } else {
      if (callResult.errorStatus === 401) {
        logout();
      }
    }
    setLoading(false);
  };

  const deleteManufacturedProduct = async function (id) {
    let callResult = await makePostApiCall(`deleteManufacturedProduct/${id}`);

    if (callResult.success) {
      const filtereditems = productionHistories.data.filter(
        (item) => item.id !== id
      );
      setProductionHistories({ ...productionHistories, data: filtereditems });

      setEmptyModelOpen({
        open: false,
        childComponent: null,
      });
      setShowSimpleNotification({
        show: true,
        title: callResult.data.message,
        type: BannerNotificationType.Success,
      });
    } else {
      if (callResult.errorStatus === 401) {
        logout();
      }
    }
  };

  const askDeleteManufacturedProduct = function (product) {
    let product_name = product.product_name ? product.product_name : "product";
    setEmptyModelOpen({
      childComponent: (
        <DeleteConfirmationForm
          buttonMessage="Delete"
          confirmAction={() => deleteManufacturedProduct(product.id)}
          message={`Are you sure you want to ${product_name}?`}
          setOpen={setEmptyModelOpen}
        />
      ),
      open: true,
    });
  };

  const resetFilters = () => {
    const filterReset = {
      product: { value: -1, label: "All Products" },
      supervisor: { value: -1, label: "All Employees" },
      employee1: { value: -1, label: "All Employees" },
      employee2: { value: -1, label: "All Employees" },
      certification: { value: -1, label: "All Certifications" },
      recipe: { value: -1, label: "All Recipes" },
      grandTotal: null,
      startDate: null,
      endDate: null,
    };
    setFilters(filterReset);

    setShowFilters(false);
    getManufacturedProducts(null, 1, filterReset);
  };

  const getEmployeesForDropdown = async function (type, department) {
    const payload = { department: department, type: [type] };
    let callResult = await makePostApiCall(`employeesForDropdown`, payload);

    if (callResult.success) {
      if (type === 1) {
        setEmployeeOptions(callResult.data);
      } else {
        setSupervisorOptions(callResult.data);
      }
    } else {
      if (callResult.errorStatus === 401) {
        logout();
      }
    }
  };

  const fetchProductsForDropdown = async function () {
    let callResult = await makePostApiCall(`fetchProductsForDropdown`);

    if (callResult.success) {
      setProductsForDropdown([
        { value: -1, label: "All Products" },
        ...callResult.data,
      ]);
    } else {
      if (callResult.errorStatus === 401) {
        logout();
      }
    }
  };

  const getCertifiedByOptionsForDropdown = async function () {
    let callResult = await makePostApiCall(`certifiedByOptionsForDropdown`);

    if (callResult.success) {
      setCertifications([
        { value: -1, label: "All Certifications" },
        ...callResult.data,
      ]);
    } else {
      if (callResult.errorStatus === 401) {
        logout();
      }
    }
  };

  const fetchRecipesForDropdown = async function () {
    let callResult = await makePostApiCall(`recipesForDropdown`);

    if (callResult.success) {
      setRecipes([{ value: -1, label: "All Recipes" }, ...callResult.data]);
    } else {
      if (callResult.errorStatus === 401) {
        logout();
      }
    }
  };

  const filterComponents = [
    <DateRangeFilter
      endDate={filters.endDate}
      onChange={(name, value) =>
        setFilters({
          ...filters,
          [name]: value,
        })
      }
      resetFilter={() =>
        setFilters({
          ...filters,
          startDate: null,
          endDate: null,
        })
      }
      startDate={filters.startDate}
    />,
    <FilterSingleSelectDropdown
      header="Product"
      onChange={(value) =>
        setFilters({
          ...filters,
          product: value,
        })
      }
      options={productsForDropdown}
      resetFilter={() =>
        setFilters({
          ...filters,
          product: { value: -1, label: "All Products" },
        })
      }
      valueName="product"
      value={filters.product}
    />,
    <FilterInput
      header="Search by product lot"
      onChange={(value) =>
        setFilters({
          ...filters,
          product_lot: value,
        })
      }
      resetFilter={() =>
        setFilters({
          ...filters,
          product_lot: null,
        })
      }
      valueName="product_lot"
      value={filters.product_lot}
    />,
    <FilterSingleSelectDropdown
      header="Certification"
      onChange={(value) =>
        setFilters({
          ...filters,
          certification: value,
        })
      }
      options={certifications}
      resetFilter={() =>
        setFilters({
          ...filters,
          certification: { value: -1, label: "All Certifications" },
        })
      }
      valueName="certification"
      value={filters.certification}
    />,
    <FilterSingleSelectDropdown
      header="Recipe"
      onChange={(value) =>
        setFilters({
          ...filters,
          recipe: value,
        })
      }
      options={recipes}
      resetFilter={() =>
        setFilters({
          ...filters,
          recipe: { value: -1, label: "All Recipes" },
        })
      }
      valueName="recipe"
      value={filters.recipe}
    />,
    <FilterSingleSelectDropdown
      header="Supervisor"
      onChange={(value) =>
        setFilters({
          ...filters,
          supervisor: value,
        })
      }
      options={supervisorOptions}
      resetFilter={() =>
        setFilters({
          ...filters,
          supervisor: { value: -1, label: "All Employees" },
        })
      }
      valueName="supervisor"
      value={filters.supervisor}
    />,
    <FilterSingleSelectDropdown
      header="Employee 1"
      onChange={(value) =>
        setFilters({
          ...filters,
          employee1: value,
        })
      }
      options={employeeOptions}
      resetFilter={() =>
        setFilters({
          ...filters,
          employee1: { value: -1, label: "All Employees" },
        })
      }
      valueName="employee1"
      value={filters.employee1}
    />,
  ];

  const generateProductionReport = async function () {
    setReportInProgress(true);
    let callResult = await makePostApiCall(
      `generateProductionHistoriesReport`,
      reportFilters
    );
    if (callResult.success) {
      setShowReports(false);
      window.open(callResult.data.location, "_blank");
    } else {
      if (callResult.errorStatus === 401) {
        logout();
      }
    }
    setReportInProgress(false);
  };

  const reportComponents = [
    <FilterSingleSelectDropdown
      header="Report type"
      onChange={(value) =>
        setReportFilters({
          ...reportFilters,
          type: value,
        })
      }
      options={reportTypeOptions}
      resetFilter={() =>
        setReportFilters({
          ...reportFilters,
          type: { value: -1, label: "Select report" },
        })
      }
      valueName="type"
      value={reportFilters.type}
    />,
    <DateRangeFilter
      endDate={reportFilters.endDate}
      onChange={(name, value) =>
        setReportFilters({
          ...reportFilters,
          [name]: value,
        })
      }
      maxDate={new Date()}
      resetFilter={() =>
        setReportFilters({
          ...reportFilters,
          startDate: null,
          endDate: null,
        })
      }
      startDate={reportFilters.startDate}
    />,
  ];

  const getAnalytics = async function (search, filterArg) {
    setAnalytics({ ...analytics, loading: true });
    const payload = {
      filters: { ...filterArg, search: search },
    };
    let callResult = await makePostApiCall(
      `fetchManufacturingAnalytics`,
      payload
    );
    setAnalytics({ ...analytics, loading: false });
    if (callResult.success) {
      setAnalytics({ ...analytics, ...callResult.data });
    } else {
      if (callResult.errorStatus === 401) {
        logout();
      }
      showErrorModal("Could not load analytics");
    }
  };

  const showErrorModal = (title, subtitle) => {
    setEmptyModelOpen({
      childComponent: (
        <ModelErrorComponent
          setOpen={setEmptyModelOpen}
          subtitle={subtitle}
          title={title}
        />
      ),
      open: true,
    });
  };

  return {
    emptyModal,
    fetchRecipesForDropdown,
    getCertifiedByOptionsForDropdown,
    getManufacturedProducts,
    loading,
    productionHistories,
    showSimpleNotification,
    setEmptyModelOpen,
    setShowSimpleNotification,
    setSlideOver,
    slideOver,
    askDeleteManufacturedProduct,
    filters,
    resetFilters,
    showFilters,
    setShowFilters,
    filterComponents,
    getEmployeesForDropdown,
    generateProductionReport,
    reportComponents,
    showReports,
    setShowReports,
    reportInProgress,
    analytics,
    setFilters,
    setShowDates,
    showDates,
    fetchProductsForDropdown,
  };
}
