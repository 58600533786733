import React, { useEffect, useState } from "react";

import { useForm } from "../useForm";

import NewPackageDetails2 from "./newComponents/NewPackageDetails2";
import ModelErrorComponent from "../../modals/ModelErrorComponent";
import ReceivedMaterialsForPackaging from "./newComponents/ReceivedMaterialsForPackaging";
import ListOfBrandsTable from "./newComponents/ListOfBrandsTable";
import WeightScalePad from "../../controls/WeightScalePad";
import WeightScale from "../../controls/WeightScale";
import ButtonSpinner from "../../controls/ButtonSpinner";
import NewPackageDetails1V2 from "./newComponents/NewPackageDetails1V2";

const initialFValues = {
  product_id: -1,
  production_product_id: -1,
  packaging_date: new Date(),
  production_date: "",
  best_before_date: "",
  packing_size: "",
  shelf_life: "",
  serial_number: "",
  supervisor: [],
  employee_1: [],
  employee_2: [],
  room_temperature: "",
  product_temperature: "",
  shipping_barcode: "",
  description_en: "",
  packaging_material_1: "",
  packaging_material_2: "",
  number_of_label_to_print: 1,
  brand_id: -1,
  brand_name: null,
  is_default_brand: false,
  skid_label_number: "",
  skid_label_required: false,
  passed_from_metal_detector: true,
  product_lot: "",
  is_scale: false,
};

export default function NewPackageFormV2(props) {
  const {
    employeeOptions,
    isEdit,
    getManufacturedItemByLot,
    packagedProduct,
    setEmptyModelOpen,
    setSlideOver,
    savePackagingDetailsAndPrint,
    brands,
    showErrorModal,
    createSkidLabelBarcode,
    setScaleIsOpen,
    scaleIsOpen,
    printedPackageItems,
    deleteSinglePackagedItem,
    reprintPackageLabel,
    fetchProductByPlu,
    printInProgress,
    getInContactPackagingSupplies,
    printSkidLabelInProgress,
    reprintInProgress,
    deleteInProgress,
    supervisorOptions,
  } = props;

  const [barcodeScanInProgress, setBarcodeScanInProgress] = useState(false);

  const [showNumberPad, setShowNumberPad] = useState({
    show: false,
    name: null,
  });
  const [showWeightScalePad, setShowWeightScalePad] = useState({
    show: false,
    name: null,
  });

  const validate = (fieldValues = values) => {
    let temp = { ...errors };

    temp.production_product_id = null;
    if (values.production_product_id < 1) {
      temp.production_product_id = "Please scan a product.";
      showErrorModal("Please scan a product");
    }

    temp.packaging_date = null;
    if (values.packaging_date === "") {
      temp.packaging_date = "Please select a date.";
    }

    temp.supervisor = null;
    if (values.supervisor.id < 1) {
      temp.supervisor = "Please select a supervisor.";
    }

    // temp.packaging_material_1 = null;
    // if (values.packaging_material_1 === "") {
    //   temp.packaging_material_1 = "Please select a material.";
    // }

    temp.skid_label_number = null;
    if (values.skid_label_number === "" && values.skid_label_required) {
      temp.skid_label_number = "Please enter a skid label number.";
    }

    temp.brand_id = null;
    if (values.brand_id < 1) {
      temp.brand_id = "Please choose a brand.";
      showErrorModal("Please select a brand");
    }

    temp.number_of_label_to_print = null;
    if (
      values.number_of_label_to_print === "" ||
      parseInt(values.number_of_label_to_print) < 1
    ) {
      temp.number_of_label_to_print = "Please select a number more than 0.";
    }

    setErrors({
      ...temp,
    });

    if (fieldValues === values) {
      return Object.values(temp).every((x) => x === null);
    }
  };

  const { values, setValues, errors, setErrors, handleInputChange } = useForm(
    initialFValues,
    false,
    validate
  );

  const handleBarcodeScan = async function (scannedBarcode) {
    if (scannedBarcode || scannedBarcode !== "") {
      setBarcodeScanInProgress(true);

      const fetchedItem = await getManufacturedItemByLot(scannedBarcode);

      if (fetchedItem) {
        const isFreeWeight = !parseFloat(fetchedItem.data.packing_size) > 0.0;
        setValues({
          ...values,
          production_product_id: fetchedItem.data.id,
          user_id: fetchedItem.data.user_id,
          product_id: fetchedItem.data.product_id,
          production_date: fetchedItem.data.production_date,
          best_before_date: fetchedItem.data.shelf_life_date,
          is_free_weight: isFreeWeight,
          packing_size: fetchedItem.data.packing_size,
          shelf_life: fetchedItem.data.shelf_life,
          description_en: fetchedItem.data.description_en,
          product_barcode: fetchedItem.data.product_barcode,
          brand: fetchedItem.data.brand,
          product_code: fetchedItem.data.plu,
          product_name: fetchedItem.data.product_name,
          product_lot: fetchedItem.data.new_lot_number,
          number_of_label_to_print: isFreeWeight
            ? 1
            : values.number_of_label_to_print,
        });
      } else {
        setEmptyModelOpen({
          childComponent: (
            <ModelErrorComponent
              setOpen={setEmptyModelOpen}
              subtitle="We could not find that product!"
              title="Error"
            />
          ),
          open: true,
        });
      }

      setBarcodeScanInProgress(false);
    }
  };

  const openScaleHID = async (scaleIsOpen) => {
    setScaleIsOpen(scaleIsOpen);
    if ("hid" in navigator && scaleIsOpen) {
      const filters = [];

      const [device] = await navigator.hid.requestDevice({ filters });

      if (device) {
        await device.open();
        device.addEventListener("inputreport", (event) => {
          const { data, device, reportId } = event;

          const int8Array = new Uint8Array(data.buffer);

          let weightLsb = int8Array[3];
          let weightMsb = int8Array[4];

          let scale_weight = ((weightMsb * 256 + weightLsb) * 10) / 1000;

          if (isNaN(scale_weight)) return;
          scale_weight = scale_weight.toFixed(2);
          const scale_weight_str = `${scale_weight}`;
          setValues({ ...values, packing_size: scale_weight_str });
        });
      }
    }
  };

  const openPackagingOptions = (item1) => {
    setSlideOver({
      childComponent: (
        <ReceivedMaterialsForPackaging
          getInContactPackagingSupplies={getInContactPackagingSupplies}
          setOpen={setSlideOver}
          setItemValues={(packagingItem) => {
            var packagingObj = null;
            if (item1) {
              packagingObj = { packaging_material_1: packagingItem };
            } else {
              packagingObj = { packaging_material_2: packagingItem };
            }

            setValues({
              ...values,
              ...packagingObj,
            });
          }}
        />
      ),
      open: true,
    });
  };

  const handleSavePackageAndPrint = async function () {
    if (validate()) {
      const createdPackaging = await savePackagingDetailsAndPrint({
        ...values,
        is_scale: scaleIsOpen,
      });
    }
  };

  const showBrands = () => {
    setSlideOver({
      childComponent: (
        <ListOfBrandsTable
          brands={brands}
          setItemValues={(brand_id, brand_name) => {
            setValues({
              ...values,
              brand_id: brand_id,
              brand_name: brand_name,
            });
            setSlideOver({
              open: false,
              childComponent: null,
            });
          }}
        />
      ),
      open: true,
      size: "max-w-xl",
    });
  };

  const openSkidLabelInput = () => {
    setValues({ ...values, skid_label_required: !values.skid_label_required });
  };

  useEffect(() => {
    brands.data.forEach((brand) => {
      if (brand.is_default_brand === 1) {
        setValues({
          ...values,
          brand_id: brand.id,
          brand_name: brand.name,
          is_default_brand: brand.is_default_brand === 1,
        });
      }
    });
  }, [brands]);

  return (
    <div className="px-4 sm:px-6 lg:px-4">
      <div className="sm:flex sm:items-center px-4 py-5 sm:px-4 card-custom">
        <div className="sm:flex-auto">
          <h1 className="text-base font-semibold leading-6 text-gray-900">
            {values.brand_name
              ? `Packaging product for ${values.brand_name}`
              : "Package new product, please select brand"}
            <br /> V2
          </h1>
        </div>
      </div>
      <div className="mt-5 grid grid-cols-1 sm:grid-cols-3 gap-4">
        <NewPackageDetails1V2
          employeeOptions={employeeOptions}
          supervisorOptions={supervisorOptions}
          errors={errors}
          isEdit={isEdit}
          handleBarcodeScan={handleBarcodeScan}
          handleInputChange={handleInputChange}
          packagedProduct={packagedProduct}
          values={values}
          openScaleHID={openScaleHID}
          openPackagingOptions={openPackagingOptions}
          handleSavePackageAndPrint={handleSavePackageAndPrint}
          showNumberPad={showNumberPad}
          setShowNumberPad={setShowNumberPad}
          showBrands={showBrands}
          barcodeScanInProgress={barcodeScanInProgress}
          openSkidLabelInput={openSkidLabelInput}
          setScaleIsOpen={setScaleIsOpen}
          scaleIsOpen={scaleIsOpen}
          showErrorModal={showErrorModal}
          printInProgress={printInProgress}
        />
        <NewPackageDetails2
          errors={errors}
          handleInputChange={handleInputChange}
          values={values}
          showNumberPad={showNumberPad}
          setShowNumberPad={setShowNumberPad}
          createSkidLabelBarcode={createSkidLabelBarcode}
          fetchProductByPlu={fetchProductByPlu}
          setValues={setValues}
          printSkidLabelInProgress={printSkidLabelInProgress}
        />
        <WeightScalePad
          showWeightScalePad={showWeightScalePad}
          setShowWeightScalePad={setShowWeightScalePad}
          values={values}
        />
        <div className="gap-2">
          {scaleIsOpen ? <WeightScale newWeight={values.packing_size} /> : null}
          {/* <div className="mt-5">
            <Numberpad
              showNumberPad={showNumberPad}
              setShowNumberPad={setShowNumberPad}
              values={values}
            />
          </div> */}
        </div>
      </div>
      <div className="mt-5">
        <div className="mt-8 flow-root">
          <div className="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
            <div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
              <div className="overflow-hidden card-custom">
                <table className="min-w-full divide-y divide-gray-300">
                  <thead className="bg-white">
                    <tr>
                      <th
                        scope="col"
                        className="py-3 pl-3 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6"
                      >
                        Serial Number
                      </th>
                      <th
                        scope="col"
                        className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                      >
                        PLU
                      </th>
                      <th
                        scope="col"
                        className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                      >
                        Product Lot
                      </th>
                      <th
                        scope="col"
                        className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                      >
                        Name
                      </th>
                      <th
                        scope="col"
                        className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                      >
                        Description
                      </th>
                      <th
                        scope="col"
                        className="relative py-3.5 pl-3 pr-4 sm:pr-6"
                      ></th>
                    </tr>
                  </thead>
                  <tbody className="divide-y divide-gray-200 bg-white">
                    {printedPackageItems.length > 0 ? (
                      printedPackageItems.map((assemblyItem, index) => (
                        <tr key={assemblyItem.package_id}>
                          <td className="whitespace-nowrap py-1.5 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6">
                            {assemblyItem.serial_number}
                          </td>
                          <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                            {assemblyItem.product_plu}
                          </td>
                          <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                            {assemblyItem.product_lot}
                          </td>
                          <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                            {assemblyItem.product_name}
                          </td>
                          <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                            {assemblyItem.product_description}
                          </td>
                          <td className="relative whitespace-nowrap py-1.5 pl-3 pr-4 text-right text-sm font-medium sm:pr-6">
                            <button
                              onClick={() => {
                                reprintPackageLabel(assemblyItem);
                              }}
                              className="rounded-md bg-main-purple px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-600 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                              disabled={reprintInProgress}
                            >
                              <ButtonSpinner
                                spin={reprintInProgress}
                                text="Print again"
                              />
                            </button>
                            <button
                              onClick={() => {
                                deleteSinglePackagedItem(
                                  assemblyItem.package_id
                                );
                              }}
                              className="ml-2 rounded-md bg-red-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-red-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-red-600"
                              disabled={deleteInProgress}
                            >
                              <ButtonSpinner
                                spin={deleteInProgress}
                                text="Delete"
                              />
                            </button>
                          </td>
                        </tr>
                      ))
                    ) : (
                      <tr>
                        <td colSpan={6} className="py-5 text-center">
                          Please start scanning products
                        </td>
                      </tr>
                    )}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
