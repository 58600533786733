import { useEffect } from "react";

import { connect } from "react-redux";

import * as actions from "../../store/actions/index";

import { withRouter } from "../../shared/utility";

import SlideOver from "../../components/slideOver/SlideOver";

import NewPackageFormV2 from "../../components/forms/packaging/NewPackageFormV2";
import EmptyModal from "../../components/modals/EmptyModal";
import SimpleBanner from "../../components/SimpleBanner";
import { NewPackageViewModelV2 } from "../../viewmodels/packaging/NewPackageViewModelV2";

function NewPackageScreenV2(props) {
  const { logout } = props;
  const {
    getManufacturedItemByLot,
    emptyModal,
    setEmptyModelOpen,
    setShowSimpleNotification,
    setSlideOver,
    showSimpleNotification,
    slideOver,
    employeeOptions,
    getEmployeesForDropdown,
    getInContactPackagingSupplies,
    savePackagingDetailsAndPrint,
    getBrands,
    brands,
    showErrorModal,
    createSkidLabelBarcode,
    scaleIsOpen,
    setScaleIsOpen,
    printedPackageItems,
    deleteSinglePackagedItem,
    reprintPackageLabel,
    fetchProductByPlu,
    printInProgress,
    printSkidLabelInProgress,
    reprintInProgress,
    deleteInProgress,
    supervisorOptions,
  } = NewPackageViewModelV2(logout);

  useEffect(() => {
    getEmployeesForDropdown(2, [1, 2]);
    getEmployeesForDropdown(1, [1, 2]);
    getBrands();
  }, []);

  return (
    <div>
      <SimpleBanner
        setShow={setShowSimpleNotification}
        showSimpleNotification={showSimpleNotification}
      />
      <div className="hide-for-print mt-2">
        <NewPackageFormV2
          employeeOptions={employeeOptions}
          isEdit={false}
          getManufacturedItemByLot={getManufacturedItemByLot}
          packagedProduct={null}
          setEmptyModelOpen={setEmptyModelOpen}
          setSlideOver={setSlideOver}
          savePackagingDetailsAndPrint={savePackagingDetailsAndPrint}
          getInContactPackagingSupplies={getInContactPackagingSupplies}
          brands={brands}
          showErrorModal={showErrorModal}
          createSkidLabelBarcode={createSkidLabelBarcode}
          setScaleIsOpen={setScaleIsOpen}
          scaleIsOpen={scaleIsOpen}
          printedPackageItems={printedPackageItems}
          deleteSinglePackagedItem={deleteSinglePackagedItem}
          reprintPackageLabel={reprintPackageLabel}
          fetchProductByPlu={fetchProductByPlu}
          printInProgress={printInProgress}
          printSkidLabelInProgress={printSkidLabelInProgress}
          reprintInProgress={reprintInProgress}
          deleteInProgress={deleteInProgress}
          supervisorOptions={supervisorOptions}
        />
      </div>

      <EmptyModal
        childComponent={emptyModal.childComponent}
        open={emptyModal.open}
        setOpen={setEmptyModelOpen}
      />
      <SlideOver
        childComponent={slideOver.childComponent}
        customClass={slideOver.size ? slideOver.size : "max-w-7xl"}
        slideOver={slideOver}
        setSlideOver={setSlideOver}
      />
    </div>
  );
}

const mapStateToProps = (state) => {
  return {};
};

const mapDispatchToProps = (dispatch) => {
  return {
    logout: () => dispatch(actions.logout()),
  };
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(NewPackageScreenV2)
);
