import { useEffect } from "react";
import { ProductViewModel } from "../../../../viewmodels/setup/myProducts/ProductViewModel";

import { connect } from "react-redux";

import * as actions from "../../../../store/actions/index";

import { withRouter } from "../../../../shared/utility";

import ProductDetailsForm from "../../../../components/forms/setup/product/ProductDetailsForm";
import EmptyModal from "../../../../components/modals/EmptyModal";

function AddProduct(props) {
  const { logout } = props;
  const {
    allergensOptions,
    createProduct,
    getAllergensForDropdown,
    unitOfMeasureOptions,
    getUnitOfMeasuresForDropdown,
    getProductTypes,
    productTypeOptions,
    emptyModal,
    setEmptyModelOpen,
  } = ProductViewModel(logout);

  useEffect(() => {
    getAllergensForDropdown();
    getUnitOfMeasuresForDropdown();
    getProductTypes();
  }, []);

  return (
    <div>
      <div className="mt-2">
        <ProductDetailsForm
          actionCall={createProduct}
          allergensOptions={allergensOptions}
          isEdit={false}
          unitOfMeasureOptions={unitOfMeasureOptions}
          productTypeOptions={productTypeOptions}
        />
      </div>
      <EmptyModal
        childComponent={emptyModal.childComponent}
        open={emptyModal.open}
        setOpen={setEmptyModelOpen}
      />
    </div>
  );
}

const mapStateToProps = (state) => {
  return {};
};

const mapDispatchToProps = (dispatch) => {
  return {
    logout: () => dispatch(actions.logout()),
  };
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(AddProduct)
);
