import React, { useRef } from "react";

import Input from "../../../controls/Input";
import SelectMenu from "../../../controls/SelectMenu";
import DatePickerInput from "../../../controls/DatePickerInput";
import InputWithSearch from "../../../controls/InputWithSearch";
import ButtonSpinner from "../../../controls/ButtonSpinner";

export default function NewPackageDetails1V2(props) {
  const {
    employeeOptions,
    errors,
    isEdit,
    handleBarcodeScan,
    handleInputChange,
    packagedProduct,
    values,
    openScaleHID,
    openPackagingOptions,
    handleSavePackageAndPrint,
    showNumberPad,
    setShowNumberPad,
    showBrands,
    barcodeScanInProgress,
    openSkidLabelInput,
    scaleIsOpen,
    showErrorModal,
    printInProgress,
    supervisorOptions,
  } = props;

  const searchRef = useRef();

  return (
    <div className="p-4 card-custom">
      <div className="grid grid-cols-1 md:grid-cols-2 gap-2">
        <div className="col-span-2">
          <InputWithSearch
            name="searchInput"
            actionCall={() => handleBarcodeScan(values.searchInput)}
            onChange={handleInputChange}
            placeholder="Type or Scan Lot Number"
            ref={searchRef}
            type="search"
            value={values.searchInput}
            disableSearch={barcodeScanInProgress}
            error={errors.searchInput}
          />
        </div>
        <div className="col-span-2">
          <SelectMenu
            handleInputChange={handleInputChange}
            name="supervisor"
            options={supervisorOptions}
            defaultValue={isEdit ? packagedProduct.supervisor : null}
            title={"Supervisor"}
            error={errors.supervisor}
          />
        </div>
        <div className="col-span-2">
          <SelectMenu
            handleInputChange={handleInputChange}
            name="employee_1"
            options={employeeOptions}
            title={"Employee 1"}
            isMulti={true}
          />
        </div>
        <div className="col-span-2">
          <InputWithSearch
            label="Packaging Material 1"
            name="packaging_material_1"
            onChange={handleInputChange}
            actionCall={() => openPackagingOptions(true)}
            ref={searchRef}
            value={values.packaging_material_1}
            labelOn={true}
            type="text"
            error={errors.packaging_material_1}
          />
        </div>
        <div className="col-span-2">
          <InputWithSearch
            label="Packaging Material 2"
            name="packaging_material_2"
            onChange={handleInputChange}
            actionCall={() => openPackagingOptions(false)}
            ref={searchRef}
            value={values.packaging_material_2}
            labelOn={true}
            type="text"
          />
        </div>
        <div className="col-span-2">
          <DatePickerInput
            label="Packaging date"
            name="packaging_date"
            onChange={handleInputChange}
            value={values.packaging_date ? values.packaging_date : new Date()}
            labelOn={true}
            // minDate={new Date()}
            type="text"
            className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-sky-500 focus:border-sky-500 sm:text-sm"
            error={errors.packaging_date}
          />
        </div>
        <div className="col-span-1">
          <Input
            label="Room Temp C&deg;"
            name="room_temperature"
            onChange={handleInputChange}
            onClick={() => {
              setShowNumberPad({
                show: true,
                name: "room_temperature",
                onChange: handleInputChange,
                value: values.room_temperature,
                showNumberPad: showNumberPad,
                setShowNumberPad: setShowNumberPad,
              });
            }}
            value={values.room_temperature}
            labelOn={true}
            type="text"
            className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-35-custom px-3 focus:outline-none focus:ring-sky-500 focus:border-sky-500 sm:text-sm"
            error={errors.production_room}
          />
        </div>
        <div className="col-span-1">
          <Input
            label="Product Temp C&deg;"
            name="product_temperature"
            onChange={handleInputChange}
            onClick={() => {
              setShowNumberPad({
                show: true,
                name: "product_temperature",
                onChange: handleInputChange,
                value: values.product_temperature,
                showNumberPad: showNumberPad,
                setShowNumberPad: setShowNumberPad,
              });
            }}
            value={values.product_temperature}
            labelOn={true}
            type="text"
            className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-35-custom px-3 focus:outline-none focus:ring-sky-500 focus:border-sky-500 sm:text-sm"
            //error={errors.production_room}
          />
        </div>
        <div className="col-span-2">
          <div className="grid grid-cols-2 md:grid-cols-4 gap-2">
            <button
              type="button"
              className="rounded-md bg-main-purple px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-600 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600  align-middle h-16"
              onClick={showBrands}
            >
              Brand
            </button>
            <button
              type="button"
              className="rounded-md bg-main-purple px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-600 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600 h-16"
              onClick={() => {
                openScaleHID(!scaleIsOpen);
              }}
            >
              Scale
            </button>
            <button
              type="button"
              className="rounded-md bg-main-purple px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-600 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600 h-16"
              onClick={() => openSkidLabelInput()}
            >
              Skid label
            </button>
            <button
              type="button"
              className="rounded-md bg-green-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-green-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-green-600 h-16"
              onClick={() => {
                var canPrint = true;
                if (values.is_free_weight) {
                  if (values.packing_size > 0) {
                    canPrint = true;
                  } else {
                    canPrint = false;
                    showErrorModal(
                      "Case weight cannot be 0kg for this product"
                    );
                  }
                }
                if (canPrint) {
                  handleSavePackageAndPrint(values);
                }
              }}
              disabled={printInProgress}
            >
              <ButtonSpinner spin={printInProgress} text="Print" />
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}
