import { useRef, useState } from "react";
import { TraceabilityViewModel } from "../../viewmodels/traceability/TraceabilityViewModel";
import { MagnifyingGlassIcon } from "@heroicons/react/24/outline";

import { connect } from "react-redux";

import * as actions from "../../store/actions/index";

import { withRouter } from "../../shared/utility";

import SlideOver from "../../components/slideOver/SlideOver";

import ReceivingItemInformation from "../../components/forms/production/ReceivingItemInformation";
import TraceShippingBarcode from "../../components/forms/traceability/TraceShippingBarcode";
import TraceLotNumber from "../../components/forms/traceability/TraceLotNumber";
import EmptyModal from "../../components/modals/EmptyModal";
import SelectMenu from "../../components/controls/SelectMenu";
import TraceSupplierItem from "../../components/forms/traceability/TraceSupplierItem";
import { Skeleton } from "primereact/skeleton";

const reportTypes = [
  { value: 0, label: "Manufactured product" },
  { value: 1, label: "Supplier item" },
];

function Traceability(props) {
  const { logout } = props;
  const {
    loading,
    product,
    setProduct,
    slideOver,
    setSlideOver,
    onTraceBarcode,
    emptyModal,
    setEmptyModelOpen,
  } = TraceabilityViewModel(logout);

  const [reportType, setReportType] = useState(0);

  const searchRef = useRef();

  const handleSearch = () => {
    onTraceBarcode(searchRef.current.value, reportType);
  };

  const openReceivingItemInformation = function (assemblyItem) {
    setSlideOver({
      childComponent: (
        <ReceivingItemInformation
          receivingItem={assemblyItem}
          setOpen={setSlideOver}
        />
      ),
      open: true,
    });
  };

  const renderTraceResults = function () {
    if (reportType.value === 0) {
      switch (product.traceability_type) {
        case "shipping_barcode":
          return (
            <TraceShippingBarcode
              product={product}
              openReceivingItemInformation={openReceivingItemInformation}
            />
          );
        case "lot_number":
          return (
            <TraceLotNumber
              product={product}
              openReceivingItemInformation={openReceivingItemInformation}
            />
          );

        default:
          return null;
      }
    } else {
      return <TraceSupplierItem product={product} />;
    }
  };

  return (
    <div>
      <div className="mt-2 lg:px-8">
        <div className="sm:flex sm:items-center px-4 py-5 card-custom">
          <div className="sm:flex-auto">
            <h1 className="text-base font-semibold leading-6 text-gray-900">
              Traceability
            </h1>
            <div className="mt-5 flex rounded-md">
              <div className="relative flex items-stretch flex-grow focus-within:z-10">
                <input
                  type="search"
                  name="searchProduct"
                  id="searchProduct"
                  className="block w-full rounded-lg rounded-l-md pl-2 sm:text-sm border border-gray-300 rounded-tr-none rounded-br-none"
                  placeholder="Search by shipping barcode, lot or serial number"
                  ref={searchRef}
                  onKeyDown={(e) => {
                    if (e.key === "Enter") handleSearch();
                  }}
                />
              </div>
              <button
                type="button"
                className="-ml-px relative inline-flex items-center space-x-2 px-4 py-2 border border-gray-300 text-sm font-medium rounded-r-md text-gray-700 bg-gray-50 hover:bg-gray-100 focus:outline-none focus:ring-1"
                onClick={() => handleSearch()}
              >
                <MagnifyingGlassIcon
                  className="h-5 w-5 text-gray-400"
                  aria-hidden="true"
                />
              </button>
              <SelectMenu
                handleInputChange={(e) => {
                  setProduct(null);
                  setReportType(e.target.value);
                }}
                name="created_by"
                options={reportTypes}
                parentClass="ml-5 rounded-md w-48"
              />
            </div>
          </div>
        </div>
        {loading ? (
          <div className="py-4 space-y-4">
            <Skeleton
              className="w-full"
              height="5rem"
              borderRadius="16px"
            ></Skeleton>

            <Skeleton
              className="w-full"
              height="5rem"
              borderRadius="16px"
            ></Skeleton>

            <Skeleton
              className="w-full"
              height="5rem"
              borderRadius="16px"
            ></Skeleton>

            <Skeleton
              className="w-full"
              height="5rem"
              borderRadius="16px"
            ></Skeleton>
          </div>
        ) : null}
        {product ? renderTraceResults() : null}
      </div>

      <EmptyModal
        childComponent={emptyModal.childComponent}
        open={emptyModal.open}
        setOpen={setEmptyModelOpen}
      />
      <SlideOver
        childComponent={slideOver.childComponent}
        slideOver={slideOver}
        customClass="max-w-3xl"
        setSlideOver={setSlideOver}
      />
    </div>
  );
}

const mapStateToProps = (state) => {
  return {};
};

const mapDispatchToProps = (dispatch) => {
  return {
    logout: () => dispatch(actions.logout()),
  };
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(Traceability)
);
