import * as actionTypes from "./actionTypes";

export const dispatchSnackbar = (snackbar) => {
  return {
    type: actionTypes.ADD_SNACKBAR,
    snackbar: snackbar,
  };
};

export const addSnackbar = (snackbar) => {
  return (dispatch) => {
    dispatch(dispatchSnackbar(snackbar));
  };
};

export const dispatchRemoveSnackbar = (snackbarId) => {
  return {
    type: actionTypes.REMOVE_SNACKBAR,
    snackbarId: snackbarId,
  };
};

export const removeSnackbar = (snackbarId) => {
  return (dispatch) => {
    dispatch(dispatchRemoveSnackbar(snackbarId));
  };
};
