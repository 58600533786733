import { useState } from "react";

import { makePostApiCall } from "../../shared/makeApiCall";
import ModelErrorComponent from "../../components/modals/ModelErrorComponent";

export function TraceabilityViewModel(logout) {
  const [emptyModal, setEmptyModelOpen] = useState({
    open: false,
    childComponent: null,
  });
  const [loading, setLoading] = useState(false);
  const [product, setProduct] = useState(null);
  const [slideOver, setSlideOver] = useState({
    childComponent: null,
    open: false,
  });

  const showErrorModal = (title, subtitle) => {
    setEmptyModelOpen({
      childComponent: (
        <ModelErrorComponent
          setOpen={setEmptyModelOpen}
          subtitle={subtitle}
          title={title}
        />
      ),
      open: true,
    });
  };

  const onTraceBarcode = async function ($scannedBarcode, reportType) {
    setLoading(true);
    setProduct(null);
    let callResult = await makePostApiCall(`traceBarcode/${$scannedBarcode}`, {
      reportType: reportType.value,
    });
    if (callResult.success) {
      setProduct(callResult.data);
    } else {
      if (callResult.errorStatus === 401) {
        logout();
      }
      showErrorModal(callResult.data.message);
    }
    setLoading(false);
  };

  return {
    loading,
    product,
    setProduct,
    slideOver,
    setSlideOver,
    onTraceBarcode,
    emptyModal,
    setEmptyModelOpen,
  };
}
