import { Link } from "react-router-dom";
import { formatDate } from "../../../shared/utility";
import TextWithLabel from "../../controls/TextWithLabel";
import TraceManufacturedProductsTable from "./supplierItem/TraceManufacturedProductsTable";
import TracePackagedProductsTable from "./supplierItem/TracePackagedProductsTable";
import { Accordion, AccordionTab } from "primereact/accordion";
import TraceSupplierItemOrdersTable from "./supplierItem/TraceSupplierItemOrdersTable";

export default function TraceSupplierItem(props) {
  const { product } = props;

  return (
    <div className="flow-root overflow-x-auto">
      <div className="inline-block min-w-full py-2 align-middle">
        <div className="gap-4 mt-2">
          <Accordion multiple activeIndex={[0]}>
            <AccordionTab header="Receiving History">
              <div className="py-2 bg-white">
                <div className="overflow-hidden p-4 flex">
                  <div className="grid grid-cols-4 gap-4 mt-2 flex-grow">
                    <TextWithLabel
                      label="Product Name"
                      text={product.receiving_item.product_name}
                    />
                    <TextWithLabel
                      label="Supplier Name"
                      text={product.receiving_item.supplier_name}
                    />
                    <TextWithLabel
                      label="Manufacturer Name"
                      text={product.receiving_item.manufacturer_name}
                    />
                    <TextWithLabel
                      label="Product Code"
                      text={product.receiving_item.product_code}
                    />
                    <TextWithLabel
                      label="Product Lot"
                      text={product.receiving_item.product_lot}
                    />
                    <TextWithLabel
                      label="Box Date"
                      text={formatDate(product.receiving_item.box_date)}
                    />
                    <TextWithLabel
                      label="Total Unit"
                      text={
                        product.receiving_item.total_unit +
                        product.receiving_item.unit_of_measure
                      }
                    />
                    <TextWithLabel
                      label="Unit Cost ($)"
                      text={product.receiving_item.unit_cost}
                    />
                    <TextWithLabel
                      label="Total Cost ($)"
                      text={product.receiving_item.total_cost}
                    />
                    <TextWithLabel
                      label="Location"
                      text={product.receiving_item.location}
                    />
                    <TextWithLabel
                      label="Zone"
                      text={product.receiving_item.zone}
                    />
                    <TextWithLabel
                      label="Invoice Number"
                      text={product.receiving_item.invoice_number}
                    />
                    <TextWithLabel
                      label="Receiving Date"
                      text={product.receiving_item.date}
                    />
                    <TextWithLabel
                      label="Product Type"
                      text={product.receiving_item.product_type}
                    />
                    <TextWithLabel
                      label="Used Weight"
                      text={product.receiving_item.used_weight}
                    />
                    <TextWithLabel
                      label="Claims"
                      text={product.receiving_item.claims}
                    />
                    <TextWithLabel
                      label="Allergens"
                      text={product.receiving_item.allergens}
                    />
                    <TextWithLabel
                      label="Preservatives"
                      text={product.receiving_item.preservatives}
                    />
                    <TextWithLabel
                      label="Received By"
                      text={product.receiving_item.received_by}
                    />
                    <TextWithLabel
                      label="In Stock"
                      text={
                        product.receiving_item.in_stock === "1" ? "Yes" : "No"
                      }
                    />
                  </div>
                  <div>
                    <Link
                      className="ml-5 rounded-md bg-main-purple px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-600 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                      to={"/receiving-invoices/" + product.receiving_item.id}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      View
                    </Link>
                  </div>
                </div>
              </div>
            </AccordionTab>
            <AccordionTab header="Manufactured Products">
              <div className="py-2">
                <TraceManufacturedProductsTable product={product} />
              </div>
            </AccordionTab>
            <AccordionTab header="Packaged Products">
              <div className="py-2">
                <TracePackagedProductsTable
                  packagedProducts={product.packaged_products}
                />
              </div>
            </AccordionTab>
            <AccordionTab header="Orders">
              <div className="py-2">
                <TraceSupplierItemOrdersTable orders={product.orders} />
              </div>
            </AccordionTab>
          </Accordion>
        </div>
      </div>
    </div>
  );
}
