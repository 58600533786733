import {
  ArrowDownIcon,
  ArrowUpIcon,
  CalendarIcon,
  ChevronDownIcon,
} from "@heroicons/react/20/solid";
import React from "react";
import { classNames } from "../../shared/utility";
import DateRangeFilter from "../filterDropdown/DateRangeFilter";
import FilterDropdown from "../filterDropdown/FilterDropdown";
import { format } from "date-fns";

interface Stat {
  name: string;
  subtitle: string | null;
  stat: string;
  previousStat: string;
  change: string;
  changeType: string;
}

interface Analytics {
  range: string;
  stats: Stat[];
  loading: Boolean;
}

interface PackagingFilters {
  product: {};
  category: {};
  supervisor: {};
  employee1: {};
  brand: {};
  startDate: string;
  endDate: string;
}

interface StatsProps {
  analytics: Analytics;
  setFilters: (filter: PackagingFilters) => {};
  filters: PackagingFilters;
  setShowDates: (show: boolean) => {};
  showDates: boolean;
  handleSearch: () => {};
  filtersEnabled: boolean;
  showFilters: boolean;
  filterComponents: {};
  resetFilters: () => {};
  setShowFilters: (show: boolean) => {};
}

const StatsComponents: React.FC<StatsProps> = ({
  analytics,
  setFilters,
  filters,
  setShowDates,
  showDates,
  handleSearch,
  filtersEnabled,
  showFilters,
  filterComponents,
  resetFilters,
  setShowFilters,
}) => {
  const start = new Date(filters.startDate);
  const end = new Date(filters.endDate);

  const rangeSummary = `${format(start, "MMM d, yyyy")} → ${format(
    end,
    "MMM d, yyyy"
  )}`;

  return (
    <div>
      <div className="flex">
        <div>
          <h3 className="text-3xl font-semibold text-gray-900">
            {rangeSummary}
          </h3>
          {/* <h3 className="text-base font-normal text-gray-600">
           {subTitle}
          </h3> */}
        </div>
        <div className="flex ml-auto my-auto">
          <DateRangeFilter
            onChange={(startDate: string, endDate: string) =>
              setFilters({
                ...filters,
                startDate: startDate,
                endDate: endDate,
              })
            }
            endDate={filters.endDate}
            startDate={filters.startDate}
            setShowDates={setShowDates}
            showDates={showDates}
            applyChanges={handleSearch}
            maxDate={new Date()}
            rangeSummary={rangeSummary}
          />
          {filtersEnabled ? (
            <FilterDropdown
              actionCall={() => handleSearch()}
              filterComponents={filterComponents}
              resetFilters={() => {
                resetFilters();
              }}
              showFilters={showFilters}
              setShowFilters={setShowFilters}
            />
          ) : null}
        </div>
      </div>
      <dl className="mt-2 grid grid-cols-1 divide-y divide-gray-200 overflow-hidden rounded-xl bg-white shadow md:grid-cols-4 md:divide-x md:divide-y-0">
        {analytics.stats.map((item) => (
          <div key={item.name} className="p-4">
            <dt className="text-base font-normal text-gray-900">{item.name}</dt>
            <dd className="mt-1 flex items-baseline gap-2">
              <div className="flex items-baseline text-2xl font-semibold flex-grow text-black mr-2">
                {item.stat}
                {/* <span className="ml-2 text-sm font-medium text-gray-500">
                  from {item.previousStat}
                </span> */}
              </div>

              {/* <div
                className={classNames(
                  item.changeType === "increase"
                    ? "bg-green-100 text-green-800"
                    : "bg-red-100 text-red-800",
                  "inline-flex items-baseline rounded-full px-2.5 py-0.5 text-sm font-medium md:mt-2 lg:mt-0"
                )}
              >
                {item.changeType === "increase" ? (
                  <ArrowUpIcon
                    aria-hidden="true"
                    className="-ml-1 mr-0.5 size-5 shrink-0 self-center text-green-500"
                  />
                ) : (
                  <ArrowDownIcon
                    aria-hidden="true"
                    className="-ml-1 mr-0.5 size-5 shrink-0 self-center text-red-500"
                  />
                )}

                <span className="sr-only">
                  {item.changeType === "increase" ? "Increased" : "Decreased"}{" "}
                  by
                </span>
                {item.change}
              </div> */}
            </dd>
          </div>
        ))}
      </dl>
    </div>
  );
};

export default StatsComponents;
