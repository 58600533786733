import React, { useEffect } from "react";
import { DashboardViewModel } from "../../viewmodels/dashboard/DashboardViewModel";

import OpenOrdersCard from "./cards/OpenOrdersCard";
import ShippedOrdersCard from "./cards/ShippedOrdersCard";
import InStockTwoMonthsCard from "./cards/InStockTwoMonthsCard";
import RecentProductionCard from "./cards/RecentProductionCard";
import RecentlyPackagedCard from "./cards/RecentlyPackagedCard";
import LowRawInventoryCard from "./cards/LowRawInventoryCard";
import SummaryCards from "./summaryCards/SummaryCards";
import BelowMaxThresholdCard from "./cards/BelowMaxThresholdCard";
import StatsComponents from "../../components/stats/StatsComponents";
import LineChart from "../../components/charts/LineChart";
import BarChart from "../../components/charts/BarChart";

interface DashboardProps {
  logout: () => void;
}

const stats = [
  {
    name: "Manufactured Inventory",
    subtitle: null,
    stat: "$475,827.18",
    previousStat: "1",
    change: "7%",
    changeType: "increase",
  },
  {
    name: "Raw Inventory",
    subtitle: null,
    stat: "$317,254.20",
    previousStat: "1",
    change: "9%",
    changeType: "increase",
  },
  {
    name: "Orders ready to shipped",
    subtitle: null,
    stat: "$70,298.52",
    previousStat: "2",
    change: "2%",
    changeType: "decrease",
  },
  {
    name: "Manufactured Products",
    subtitle: null,
    stat: "24,911 kg",
    previousStat: "3",
    change: "4%",
    changeType: "increase",
  },
];

const info3 = {
  data: [
    {
      item1: "2024060701",
      item2: "Beef Pepperoni",
      item3: "2024-10-05",
    },
    {
      item1: "2024060602",
      item2: "Macaroni And Cheese Pasta, 255 g",
      item3: "2025-06-06",
    },
    {
      item1: "2024060601",
      item2: "BBQ Debrenizer Sauage",
      item3: "2025-06-06",
    },
    {
      item1: "2024061005",
      item2: "Spicy Meatballs 50g",
      item3: "2025-06-10",
    },
    {
      item1: "2024061003",
      item2: "Tortellini 800g",
      item3: "2025-06-10",
    },
  ],
  name: "In Stock (Over 2 Months)",
};

const info6 = {
  data: [
    {
      item1: "2129",
      item2: "Beef Bacon Bits",
      item3: "245.32kg",
    },
    {
      item1: "1249",
      item2: "Turkey Bacon",
      item3: "240.82kg",
    },
    {
      item1: "2127",
      item2: "Cheese Strings 6-pack",
      item3: "312.19kg",
    },
    {
      item1: "1293",
      item2: "Beef Lasagna 907g",
      item3: "385.52kg",
    },
    {
      item1: "1525",
      item2: "Straight Cut Fried Potatoes, 900 g",
      item3: "201.86kg",
    },
  ],
  name: "Low Inventory Threshold",
};

const Dashboard: React.FC<DashboardProps> = ({ logout }) => {
  const {
    getInfo1,
    getInfo2,
    getInfo3,
    getInfo4,
    getInfo5,
    getInfo6,
    getInfo7,
    info1,
    info2,
    // info3,
    info4,
    info5,
    // info6,
    info7,
    info1Loading,
    info2Loading,
    info3Loading,
    info4Loading,
    info5Loading,
    info6Loading,
    info7Loading,
  } = DashboardViewModel(logout);

  useEffect(() => {
    // Uncomment the following lines to fetch data on mount
    // getInfo1();
    // getInfo2();
    getInfo3();
    // getInfo4();
    // getInfo5();
    // getInfo6();
    // getInfo7();
  }, []);

  const analytics = {
    range: "Welcome back Jason",
    stats: stats,
    loading: false,
  };

  return (
    <div>
      <div className="mt-2 pb-4 px-4 sm:px-6 lg:px-8">
        <div>{/* <StatsComponents analytics={analytics} /> */}</div>
        <div className="mt-8">
          <ul className="grid grid-cols-1 gap-x-6 gap-y-8 lg:grid-cols-3 xl:gap-x-8">
            <div className="space-y-6">
              {/* <InStockTwoMonthsCard info={info3} loading={info3Loading} />
              <LowRawInventoryCard info={info6} loading={info6Loading} /> */}
            </div>

            <div className="col-span-2">{/* <BarChart /> */}</div>
          </ul>
        </div>
      </div>
    </div>
  );
};

export default Dashboard;
