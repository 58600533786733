import { useEffect, useState } from "react";
import { ProductViewModel } from "../../../../viewmodels/setup/myProducts/ProductViewModel";

import { useLocation } from "react-router-dom";

import { connect } from "react-redux";

import * as actions from "../../../../store/actions/index";

import {
  classNames,
  getLastItem,
  withRouter,
} from "../../../../shared/utility";

import ProductDetailsForm from "../../../../components/forms/setup/product/ProductDetailsForm";
import SimpleBanner from "../../../../components/SimpleBanner";
import ProductSpecSheet from "../../../../components/forms/setup/product/pdf/ProductSpecSheet";
import EmptyModal from "../../../../components/modals/EmptyModal";

const tabs = [
  { name: "Product Details", href: "#" },
  { name: "Specs Sheet", href: "#" },
  { name: "Analytics", href: "#" },
];

function ProductScreen(props) {
  const { logout } = props;

  const [currentTab, setCurrentTab] = useState(0);

  const { pathname } = useLocation();
  const productId = getLastItem(pathname);

  const {
    allergensOptions,
    getAllergensForDropdown,
    getProduct,
    loading,
    product,
    setShowSimpleNotification,
    showSimpleNotification,
    updateProduct,
    unitOfMeasureOptions,
    getUnitOfMeasuresForDropdown,
    getProductTypes,
    productTypeOptions,
    emptyModal,
    setEmptyModelOpen,
  } = ProductViewModel(logout, productId);

  const onStartDependencides = async function () {
    getAllergensForDropdown();
    getUnitOfMeasuresForDropdown();
    getProduct();
    getProductTypes();
  };

  useEffect(() => {
    onStartDependencides();
  }, []);

  const renderComponent = (index) => {
    switch (index) {
      case 0:
        return (
          <div key={0} className="-mt-2 bg-white">
            {loading ? (
              "loading"
            ) : (
              <ProductDetailsForm
                allergensOptions={allergensOptions}
                product={product}
                actionCall={updateProduct}
                isEdit={true}
                unitOfMeasureOptions={unitOfMeasureOptions}
                productTypeOptions={productTypeOptions}
              />
            )}
          </div>
        );

      case 1:
        return (
          <div key={1} className="-mt-2 bg-white">
            <ProductSpecSheet product={product} />
          </div>
        );

      case 2:
        return (
          <div key={2} className="-mt-2 bg-white">
            Analytics coming soon
          </div>
        );

      default:
        return (
          <div key={1000} className="-mt-2 bg-white">
            Could not load page, please try again
          </div>
        );
    }
  };

  return (
    <div className="bg-gray-50">
      <SimpleBanner
        setShow={setShowSimpleNotification}
        showSimpleNotification={showSimpleNotification}
      />
      <header className="bg-white shadow sticky top-0 z-50 hide-for-print">
        <div className="max-w-7xl mx-auto py-4 px-4 flex items-center justify-between flex-wrap sm:flex-nowrap">
          <div className="flex px-4 sm:px-12">
            <h1 className="text-3xl font-bold text-gray-900">
              {loading ? "Loading..." : product.name_en}
            </h1>
          </div>
        </div>
      </header>
      <main>
        <div className="p-6">
          {/* Content starts here */}

          {/* tab here */}
          <div className="hide-for-print">
            <div className="sm:hidden">
              <label htmlFor="tabs" className="sr-only">
                Select a tab
              </label>
              <select
                id="tabs"
                name="tabs"
                className="block w-full  border-gray-300 rounded-md"
                defaultValue={tabs[currentTab].name}
              >
                {tabs.map((tab, index) => (
                  <option key={index}>{tab.name}</option>
                ))}
              </select>
            </div>
            <div className="hidden sm:block">
              <nav
                className="relative z-0 rounded-lg shadow flex divide-x divide-gray-200"
                aria-label="Tabs"
              >
                {tabs.map((tab, index) => (
                  <a
                    key={tab.name}
                    href={tab.href}
                    className={classNames(
                      index === currentTab
                        ? "text-gray-900"
                        : "text-gray-500 hover:text-gray-700",
                      index === 0 ? "rounded-l-lg" : "",
                      index === tabs.length - 1 ? "rounded-r-lg" : "",
                      "group relative min-w-0 flex-1 overflow-hidden bg-white py-4 px-4 text-sm font-medium text-center hover:bg-gray-50 focus:z-10"
                    )}
                    aria-current={index === currentTab ? "page" : undefined}
                    onClick={() => setCurrentTab(index)}
                  >
                    <span>{tab.name}</span>
                    <span
                      aria-hidden="true"
                      className={classNames(
                        index === currentTab
                          ? "bg-indigo-500"
                          : "bg-transparent",
                        "absolute inset-x-0 bottom-0 h-0.5"
                      )}
                    />
                  </a>
                ))}
              </nav>
            </div>
          </div>

          {/* {tab content starts below} */}
          <div className="px-4 py-6 sm:px-0">
            <div className="sm:col-span-9 py-5 border-b border-gray-200">
              {tabs.map((item, index) =>
                index === currentTab ? renderComponent(index) : null
              )}
            </div>
          </div>
        </div>
      </main>
      <EmptyModal
        childComponent={emptyModal.childComponent}
        open={emptyModal.open}
        setOpen={setEmptyModelOpen}
      />
    </div>
  );
}

const mapStateToProps = (state) => {
  return {};
};

const mapDispatchToProps = (dispatch) => {
  return {
    logout: () => dispatch(actions.logout()),
  };
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(ProductScreen)
);
