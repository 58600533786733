import { useState } from "react";

import { makePostApiCall } from "../../../shared/makeApiCall";
import { BannerNotificationType } from "../../../components/SimpleBanner";
import ModelErrorComponent from "../../../components/modals/ModelErrorComponent";

export function ProductViewModel(logout, productId) {
  const [allergensOptions, setAllergensOptions] = useState([]);
  const [loading, setLoading] = useState(true);
  const [product, setProduct] = useState({});
  const [productTypeOptions, setProductTypeOptions] = useState([]);
  const [showSimpleNotification, setShowSimpleNotification] = useState({
    show: false,
    message: null,
    title: null,
    type: BannerNotificationType.Success,
  });
  const [unitOfMeasureOptions, setUnitOfMeasureOptions] = useState([]);
  const [emptyModal, setEmptyModelOpen] = useState({
    open: false,
    childComponent: null,
  });

  const user_id = localStorage.getItem("id") * 1;

  const getProduct = async function (search, page) {
    setLoading(true);
    const payload = { id: productId };
    let callResult = await makePostApiCall(`products/${productId}`, payload);

    if (callResult.success) {
      setProduct(callResult.data.product);
    } else {
      if (callResult.errorStatus === 401) {
        logout();
      }
    }
    setLoading(false);
  };

  const createProduct = async function (values) {
    const payload = { ...values, user_id: user_id };
    let callResult = await makePostApiCall(
      "createProduct",
      payload,
      "multipart/form-data"
    );

    if (callResult.success) {
      window.location = "/my-products/" + callResult.data.product.id;
    } else {
      if (callResult.errorStatus === 401) {
        logout();
      }
      showErrorModal(callResult.data.message);
    }
  };

  const updateProduct = async function (product, values) {
    let callResult = await makePostApiCall(
      `updateProduct/${product.id}`,
      values,
      "multipart/form-data"
    );
    if (callResult.success) {
      setShowSimpleNotification({
        show: true,
        title: "Product updated",
        type: BannerNotificationType.Success,
      });
    } else {
      if (callResult.errorStatus === 401) {
        logout();
      }
      showErrorModal(callResult.data.message);
    }
  };

  const getAllergensForDropdown = async function () {
    let callResult = await makePostApiCall(`allergensForDropdown`);

    if (callResult.success) {
      setAllergensOptions(callResult.data);
    } else {
      if (callResult.errorStatus === 401) {
        logout();
      }
      showErrorModal("Could not load allergens");
    }
  };

  const getUnitOfMeasuresForDropdown = async function () {
    let callResult = await makePostApiCall(`unitOfMeasuresForDropdown`);

    if (callResult.success) {
      setUnitOfMeasureOptions(callResult.data);
    } else {
      if (callResult.errorStatus === 401) {
        logout();
      }
      showErrorModal("Could not load unit of measure types");
    }
  };

  const getProductTypes = async function () {
    let callResult = await makePostApiCall(`productTypesForDropdown`);

    if (callResult.success) {
      setProductTypeOptions(callResult.data);
    } else {
      if (callResult.errorStatus === 401) {
        logout();
      }
      showErrorModal("Could not load product types");
    }
  };

  const showErrorModal = (subtitle) => {
    setEmptyModelOpen({
      childComponent: (
        <ModelErrorComponent
          setOpen={setEmptyModelOpen}
          subtitle={subtitle}
          title="Error"
        />
      ),
      open: true,
    });
  };

  return {
    allergensOptions,
    createProduct,
    getAllergensForDropdown,
    getProduct,
    loading,
    product,
    setShowSimpleNotification,
    showSimpleNotification,
    updateProduct,
    getUnitOfMeasuresForDropdown,
    unitOfMeasureOptions,
    getProductTypes,
    productTypeOptions,
    emptyModal,
    setEmptyModelOpen,
  };
}
