export class SnackbarData {
  id: string;
  message: string;
  type: SnackbarType;

  constructor(id: string, message: string, type: SnackbarType) {
    this.id = id;
    this.message = message;
    this.type = type;
  }
}

export class SnackbarType {
  static Success = "success";
  static Error = "error";
}
