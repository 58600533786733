import { useState } from "react";

import { makePostApiCall } from "../../../shared/makeApiCall";
import ModelErrorComponent from "../../../components/modals/ModelErrorComponent";
import { BannerNotificationType } from "../../../components/SimpleBanner";

export function InventoryManufacturedMaterialViewModel(logout) {
  const [emptyModal, setEmptyModelOpen] = useState({
    open: false,
    childComponent: null,
  });
  const [loading, setLoading] = useState(false);
  const [products, setProducts] = useState({ data: [] });
  const [showSimpleNotification, setShowSimpleNotification] = useState({
    show: false,
    message: null,
    title: null,
    type: BannerNotificationType.Success,
  });

  const getInStockRawMaterial = async function (search, page) {
    setLoading(true);
    const payload = {
      page: page,
      search: search,
    };
    let callResult = await makePostApiCall(
      "inventoryManufacturedMaterial",
      payload
    );

    if (callResult.success) {
      setProducts(callResult.data);
    } else {
      if (callResult.errorStatus === 401) {
        logout();
      }
    }
    setLoading(false);
  };

  const onManufacturedInventoryAdjusted = async function (
    rowData,
    rowIndex,
    newAmount
  ) {
    const payload = {
      product_lot: rowData.product_lot,
      newAmount: newAmount,
      oldAmount: rowData.total_individual_units,
    };
    let callResult = await makePostApiCall(
      "manufacturedInventoryAdjusted",
      payload
    );

    if (callResult.success) {
      if (newAmount == 0) {
        const filteredProducts = products.data.filter(
          (element) => element.product_lot !== rowData.product_lot
        );
        setProducts({ ...products, data: filteredProducts });
      }
      setShowSimpleNotification({
        show: true,
        title: callResult.data.message,
        type: BannerNotificationType.Success,
      });
    } else {
      if (callResult.errorStatus === 401) {
        logout();
      }
    }
  };

  const onManufacturedInventoryAdjustedByLot = async function (
    rowData,
    rowIndex,
    newAmount
  ) {
    const payload = {
      product_lot: rowData.product_lot,
      newAmount: newAmount,
      oldAmount: rowData.total_individual_units,
    };
    let callResult = await makePostApiCall(
      "manufacturedInventoryAdjustByLot",
      payload
    );

    if (callResult.success) {
      getInStockRawMaterial(null, null);
      setShowSimpleNotification({
        show: true,
        title: callResult.data.message,
        type: BannerNotificationType.Success,
      });
    } else {
      if (callResult.errorStatus === 401) {
        logout();
      }
    }
  };

  const showErrorModal = (title, subtitle) => {
    setEmptyModelOpen({
      childComponent: (
        <ModelErrorComponent
          setOpen={setEmptyModelOpen}
          subtitle={subtitle}
          title={title}
        />
      ),
      open: true,
    });
  };

  const exportNetsuiteInventoryAdjustmentCsv = async function () {
    let callResult = await makePostApiCall(
      `exportNetsuiteInventoryAdjustmentCsv`
    );
    if (callResult.success) {
      window.open(callResult.data.location, "_blank").focus();
    } else {
      if (callResult.errorStatus === 401) {
        logout();
      }
    }
  };

  return {
    getInStockRawMaterial,
    loading,
    products,
    onManufacturedInventoryAdjusted,
    onManufacturedInventoryAdjustedByLot,
    showErrorModal,
    emptyModal,
    setEmptyModelOpen,
    setShowSimpleNotification,
    showSimpleNotification,
    exportNetsuiteInventoryAdjustmentCsv,
  };
}
