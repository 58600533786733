import { useState } from "react";

import {
  makePostApiCall,
  makePostApiCallToPrinter,
} from "../../shared/makeApiCall";
import { BannerNotificationType } from "../../components/SimpleBanner";
import ModelErrorComponent from "../../components/modals/ModelErrorComponent";

export function NewPackageViewModelV2(logout) {
  const [brands, setBrands] = useState({ data: [] });
  const [employeeOptions, setEmployeeOptions] = useState([]);
  const [supervisorOptions, setSupervisorOptions] = useState([]);
  const [emptyModal, setEmptyModelOpen] = useState({
    open: false,
    childComponent: null,
  });
  const [showSimpleNotification, setShowSimpleNotification] = useState({
    show: false,
    message: null,
    title: null,
    type: BannerNotificationType.Success,
  });
  const [slideOver, setSlideOver] = useState({
    childComponent: null,
    open: false,
  });
  const [scaleIsOpen, setScaleIsOpen] = useState(false);
  const [printedPackageItems, setPrintedPackageItems] = useState([]);
  const [printInProgress, setPrintInProgress] = useState(false);
  const [printSkidLabelInProgress, setPrintSkidLabelInProgress] =
    useState(false);

  const [reprintInProgress, setReprintInProgress] = useState(false);
  const [deleteInProgress, setDeleteInProgress] = useState(false);

  const getManufacturedItemByLot = async function (lot) {
    const payload = {
      lot: lot,
    };
    let callResult = await makePostApiCall(
      `/manufacturedProducts/lot/${lot}`,
      payload
    );

    if (callResult.success) {
      return callResult;
    } else {
      if (callResult.errorStatus === 401) {
        logout();
      }
    }
  };

  const getEmployeesForDropdown = async function (type, department) {
    const payload = { department: department, type: [type] };
    let callResult = await makePostApiCall(`employeesForDropdown`, payload);

    if (callResult.success) {
      if (type === 1) {
        setEmployeeOptions(callResult.data);
      } else {
        setSupervisorOptions(callResult.data);
      }
    } else {
      if (callResult.errorStatus === 401) {
        logout();
      }
    }
  };

  const getInContactPackagingSupplies = async function (page, search) {
    const payload = {
      page: page,
      search: search,
    };
    let callResult = await makePostApiCall(
      `fetchSupplierItemsByProductType/11`,
      payload
    );

    if (callResult.success) {
    } else {
      if (callResult.errorStatus === 401) {
        logout();
      }
    }
    return callResult;
  };

  const savePackagingDetailsAndPrint = async function (values) {
    setPrintInProgress(true);
    let callResult = await makePostApiCall("createNewPackagingV2", values);

    if (callResult.success) {
      setPrintedPackageItems([...printedPackageItems, ...callResult.data]);
      const dataToPrint = callResult.data;
      makePostApiCallToPrinter({ labels: dataToPrint });
    } else {
      if (callResult.errorStatus === 401) {
        logout();
      }
    }
    setPrintInProgress(false);
    return callResult;
  };

  const getBrands = async function (search) {
    const payload = {
      page: 1,
      search: search,
    };
    let callResult = await makePostApiCall(`brands`, payload);
    if (callResult.success) {
      setBrands(callResult.data);
    } else {
      if (callResult.errorStatus === 401) {
        logout();
      }
    }
  };

  const createSkidLabelBarcode = async function (
    skid_label_number,
    product_lot,
    product_name,
    product_description
  ) {
    setPrintSkidLabelInProgress(true);
    if (skid_label_number === "") {
      showErrorModal("Please generate a skid label number");
      return;
    }

    if (product_lot === "") {
      showErrorModal("Please scan a product to get lot number");
      return;
    }
    const payload = {
      skid_label_number: skid_label_number,
    };
    let callResult = await makePostApiCall(`createSkidLabelBarcode`, payload);
    if (callResult.success) {
      // setSkidLabel({
      //   barcodeHtml: callResult.data,
      //   skid_label_number: skid_label_number,
      //   product_lot: product_lot,
      //   product_name: product_name,
      //   product_description: product_description,
      // });

      setTimeout(function () {
        window.print();
      }, 125);
    } else {
      if (callResult.errorStatus === 401) {
        logout();
      }
    }
    setPrintSkidLabelInProgress(false);
  };

  const showErrorModal = (title, subtitle) => {
    setEmptyModelOpen({
      childComponent: (
        <ModelErrorComponent
          setOpen={setEmptyModelOpen}
          subtitle={subtitle}
          title={title}
        />
      ),
      open: true,
    });
  };

  const deleteSinglePackagedItem = async function (packageId) {
    setDeleteInProgress(true);
    let callResult = await makePostApiCall(
      `deleteSinglePackagedItem/${packageId}`
    );
    if (callResult.success) {
      const filteredPackages = printedPackageItems.filter(
        (item) => item.package_id !== packageId
      );
      setPrintedPackageItems(filteredPackages);
    } else {
      if (callResult.errorStatus === 401) {
        logout();
      }
    }
    setDeleteInProgress(false);
  };

  const reprintPackageLabel = async function (packageLabelDeatils) {
    // todo oggy
  };

  const fetchProductByPlu = async function (plu) {
    let callResult = await makePostApiCall(`products/plu/${plu}`);
    if (callResult.success) {
    } else {
      if (callResult.errorStatus === 401) {
        logout();
      }
      showErrorModal("Product not found");
    }
    return callResult;
  };

  return {
    getManufacturedItemByLot,
    emptyModal,
    setEmptyModelOpen,
    setShowSimpleNotification,
    setSlideOver,
    showSimpleNotification,
    slideOver,
    employeeOptions,
    getEmployeesForDropdown,
    getInContactPackagingSupplies,
    savePackagingDetailsAndPrint,
    getBrands,
    brands,
    showErrorModal,
    createSkidLabelBarcode,
    scaleIsOpen,
    setScaleIsOpen,
    printedPackageItems,
    deleteSinglePackagedItem,
    reprintPackageLabel,
    fetchProductByPlu,
    printInProgress,
    printSkidLabelInProgress,
    reprintInProgress,
    deleteInProgress,
    supervisorOptions,
  };
}
