import * as actionTypes from "../actions/actionTypes";
import { updateObject } from "../../shared/utility";

const initialState = {
  snackbarQueue: [],
};

const addSnackbar = (state, action) => {
  return updateObject(state, {
    snackbarQueue: [...state.snackbarQueue, action.snackbar],
  });
};

const removeSnackbar = (state, action) => {
  const currentSnackbarQueue = [...state.snackbarQueue];
  const newQueue = currentSnackbarQueue.filter(
    (it) => it.id !== action.snackbarId
  );

  return updateObject(state, { snackbarQueue: newQueue });
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.ADD_SNACKBAR:
      return addSnackbar(state, action);
    case actionTypes.REMOVE_SNACKBAR:
      return removeSnackbar(state, action);

    default:
      return state;
  }
};

export default reducer;
