import { connect } from "react-redux";

import * as actions from "../../store/actions/index";

import {
  classNames,
  formatDateWithTime,
  formatDateWithTimeSeconds,
  withRouter,
} from "../../shared/utility";

import { TemperatureSensorDetailsViewModel } from "../../viewmodels/qaReports/TemperatureSensorDetailsViewModel";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import FilterDropdown from "../../components/filterDropdown/FilterDropdown";
import SlideOver from "../../components/slideOver/SlideOver";
import SimpleBanner from "../../components/SimpleBanner";
import EmptyModal from "../../components/modals/EmptyModal";

import { Paginator } from "primereact/paginator";

function TemperatureSensorDetails(props) {
  const { logout } = props;

  const { sensorId } = useParams();

  const [currentTime, setCurrentTime] = useState("");

  const {
    loading,
    getSensorData,
    sensor,
    filterComponents,
    resetFilters,
    showFilters,
    setShowFilters,
    openEditProductSidePanel,
    slideOver,
    setSlideOver,
    showSimpleNotification,
    setShowSimpleNotification,
    emptyModal,
    setEmptyModelOpen,
    openTemperatureSensorReportsModal,
    filters,
  } = TemperatureSensorDetailsViewModel(logout, sensorId);

  var currentPage = 1;
  useEffect(() => {
    getSensorData(currentPage, filters);
  }, []);

  const [first, setFirst] = useState(1);
  const [rows, setRows] = useState(20);

  const handlePagination = (event) => {
    currentPage = event.page + 1;
    setFirst(event.first);
    setRows(event.rows);
    getSensorData(currentPage, filters);
  };

  useEffect(() => {
    const updateTime = () => {
      const now = new Date();
      const formattedTime = now.toLocaleString("en-US", {
        month: "short",
        day: "2-digit",
        year: "numeric",
        hour: "numeric",
        minute: "2-digit",
        hour12: true,
      });
      setCurrentTime(formattedTime);
    };

    updateTime(); // Set time on load
    const interval = setInterval(updateTime, 1000); // Update every second (optional)

    return () => clearInterval(interval); // Cleanup interval
  }, []);

  return (
    <div>
      <SimpleBanner
        setShow={setShowSimpleNotification}
        showSimpleNotification={showSimpleNotification}
      />
      <div className="hide-for-print">
        <div className="px-4 sm:px-6 lg:px-8 space-y-4">
          <div className="flex">
            <p className="ml-auto text-2xl font-semibold leading-6 text-gray-900">
              {currentTime}
            </p>
          </div>

          <div className="sm:items-center px-4 py-5 sm:px-4 card-custom">
            <div className="sm:flex gap-4 justify-between space-y-3">
              <div className="sm:flex gap-4">
                <div>
                  <p>
                    Temperature{" "}
                    <span className="font-semibold">
                      {sensor.data.length > 0
                        ? sensor.data[0].temperature + "°C"
                        : "N/A"}
                    </span>
                  </p>
                  <p>
                    Name: <span className="font-semibold">{sensor.name}</span>
                  </p>
                </div>
                <div>
                  <p>
                    Humidity:{" "}
                    <span className="font-semibold">
                      {sensor.data.length > 0
                        ? sensor.data[0].humidity + "%"
                        : "N/A"}
                    </span>
                  </p>
                  <p>
                    Sensor id:{" "}
                    <span className="font-semibold">{sensor.sensor_id}</span>
                  </p>
                </div>
                <div>
                  <p>
                    Current location:{" "}
                    <span className="font-semibold">{sensor.location}</span>
                  </p>
                  <p>
                    Status:{" "}
                    <span
                      className={classNames(
                        sensor.active ? "text-green-600" : "text-red-600",
                        "font-bold"
                      )}
                    >
                      {sensor.status_text}
                    </span>
                  </p>
                </div>
                <div>
                  <p>
                    Last seen:{" "}
                    <span className="font-semibold">
                      {sensor.data.length > 0
                        ? formatDateWithTime(sensor.data[0].created_date)
                        : "N/A"}
                    </span>
                  </p>
                </div>
              </div>
              <div className="flex gap-4">
                <div>
                  <button
                    className="sm:ml-2 rounded-md bg-gray-500 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-gray-600 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-gray-600"
                    onClick={openTemperatureSensorReportsModal}
                  >
                    Reports
                  </button>
                </div>
                <FilterDropdown
                  actionCall={() => {
                    getSensorData(currentPage, filters);
                    currentPage = 1;
                  }}
                  filterComponents={filterComponents}
                  resetFilters={() => {
                    resetFilters();
                  }}
                  showFilters={showFilters}
                  setShowFilters={setShowFilters}
                />
                <div>
                  <button
                    className="sm:ml-2 rounded-md bg-main-purple px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-600 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                    onClick={openEditProductSidePanel}
                  >
                    Edit
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div className="mt-8 flow-root">
            <div className="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
              <div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
                <div className="overflow-hidden card-custom">
                  <table className="min-w-full divide-y divide-gray-300">
                    <thead className="bg-white">
                      <tr>
                        <th
                          scope="col"
                          className="py-3 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6"
                        >
                          Location
                        </th>
                        <th
                          scope="col"
                          className="px-3 py-3 text-left text-sm font-semibold text-gray-900"
                        >
                          Date
                        </th>
                        <th
                          scope="col"
                          className="px-3 py-3 text-left text-sm font-semibold text-gray-900"
                        >
                          Temperature
                        </th>
                        <th
                          scope="col"
                          className="px-3 py-3 text-left text-sm font-semibold text-gray-900"
                        >
                          Humidity
                        </th>
                        <th
                          scope="col"
                          className="px-3 py-3 text-left text-sm font-semibold text-gray-900"
                        >
                          Battery
                        </th>
                      </tr>
                    </thead>
                    <tbody className="divide-y divide-gray-200 bg-white">
                      {sensor.data.map((entry) => (
                        <tr key={entry.created_date}>
                          <td className="whitespace-nowrap py-1.5 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6">
                            {entry.location}
                          </td>
                          <td className="whitespace-nowrap px-3 py-2 text-sm text-gray-700">
                            {formatDateWithTimeSeconds(entry.created_date)}
                          </td>
                          <td className="whitespace-nowrap px-3 py-2 text-sm text-gray-700">
                            {entry.temperature}&deg;C
                          </td>
                          <td className="whitespace-nowrap px-3 py-2 text-sm text-gray-700">
                            {entry.humidity}%
                          </td>
                          <td className="whitespace-nowrap px-3 py-2 text-sm text-gray-700">
                            {entry.battery}%
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
                <div className="w-full">
                  <Paginator
                    first={first}
                    rows={rows}
                    totalRecords={10000}
                    onPageChange={handlePagination}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <EmptyModal
        childComponent={emptyModal.childComponent}
        open={emptyModal.open}
        setOpen={setEmptyModelOpen}
      />
      <SlideOver
        childComponent={slideOver.childComponent}
        customClass="max-w-xl"
        slideOver={slideOver}
        setSlideOver={setSlideOver}
      />
    </div>
  );
}

const mapStateToProps = (state) => {
  return {};
};

const mapDispatchToProps = (dispatch) => {
  return {
    logout: () => dispatch(actions.logout()),
  };
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(TemperatureSensorDetails)
);
