import React from "react";
import { Link } from "react-router-dom";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { formatDate, orderStatusOptions } from "../../../../shared/utility";
import Pills from "../../../controls/Pills";

export default function TraceSupplierItemOrdersTable(props) {
  const { orders } = props;

  const itemActionTemplate = (rowData) => {
    return (
      <div className="ml-10">
        <Link
          className="ml-5 rounded-md bg-main-purple px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-600 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
          to={"/orders/" + rowData.id}
          target="_blank"
          rel="noopener noreferrer"
        >
          View
        </Link>
      </div>
    );
  };

  const orderDateTemplate = (rowData) => {
    return formatDate(rowData.order_date);
  };

  const convertStatus = (rowData) => {
    const statusOption = orderStatusOptions.filter(
      (el) => el.value === rowData.status
    );
    const finalStatus =
      statusOption.length === 1
        ? { text: statusOption[0].label, type: statusOption[0].type }
        : {
            text: orderStatusOptions[0].label,
            type: orderStatusOptions[0].type,
          };

    return <Pills content={finalStatus} />;
  };

  return (
    <div className="flow-root">
      <div className="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
        <div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
          <div className="overflow-hidden card-custom bg-white">
            <DataTable
              value={orders}
              dataKey="id"
              tableClassName="min-w-full bg-white divide-y divide-gray-300"
              stripedRows
            >
              <Column field="customer" header="Customer" />
              <Column field="po_number" header="PO Number" />
              <Column header="Order Date" body={orderDateTemplate} />
              <Column header="Status" body={convertStatus} />
              <Column header="" body={itemActionTemplate} />
            </DataTable>
          </div>
        </div>
      </div>
    </div>
  );
}
